import React, {Component} from 'react'
import strings from '../localization';
import {Link} from "react-router-dom";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import * as Actions from "../actions/Actions";
import { FacebookShareButton } from "react-share";
import ScrollArea from 'react-scrollbar';

const Content = {
    en: [
        <p>

“Stay clean” is the message of the great national campaign for the best entertainment without narcotics, initiated by the EXIT Foundation with the support from the Ministry of Health and Telekom Srbija, and creatively produced by McCann Belgrade with the assistance of the Drive agency. The #StayClean campaign has the goal to point the young people, in a fun and simple manner, towards educational content regarding the dangers of narcotics, presented by the Committee against drug addiction in schools at the official website, <a href='http://www.stayclean.rs'>Stayclean.rs</a>. The question “Do you know where your goods come from?”, in a brave and intriguing way focuses the attention to the detrimental consequences of taking drugs, however, it also shines a light at the unhygienic smuggling conditions that should make everyone pose a question of wanting something like that in their lives. The campaign offers numerous useful and easily understandable information about various narcotics and their influence on mental and physical health, as well as other content that includes video games that show what parts of the human body are used to transport narcotics in a very unhygienic way.
        </p>,
        <p>
            According to data gathered from research, there are over 500 drug users who have started rehabilitation. Average age of starting to experiment with drugs is 16, mostly with marijuana, and the average age of starting to use heroin is 21. The data from recent research is worrying, as it shows that there were 89 death cases from drugs, while the epidemiological data shows that the average age of heroin users who are receiving treatment is 33. Therefore, the goal of the #StayClean campaign is to act preventively, particularly for those who have yet to start consuming narcotics, as well as to dissuade those who have already started using from becoming another number in this chilling statistic.
        </p>,
        <p>
            EXIT Foundation, as the initiator of this innovative fight for the clean life, without narcotics, will be offering the rich content of the #StayClean campaign for all its visitors. This is a direct way for EXIT to talk to everyone, especially the young people, without chasing them away from this important social topic, but interest them to be better informed and, in the end, just as the campaign’s message say, #StayClean.
        </p>
    ],

    rs: [
        <p>
            &quot;Ostani čist&quot;, poruka je velike nacionalne kampanje za najbolju zabavu bez korišćenja
narkotika, koju je pokrenula EXIT fondacija uz podršku Ministarstva zdravlja i
Telekoma Srbije, a uz kreativnu izvedbu agencije McCann Beograd i podršku agencije
Drive. Kampanja #StayClean za cilj ima da mlade na jedan zabavan i krajnje jedinstven
način usmeri ka edukativnom sadržaju o štetnosti upotrebe narkotika, koji je na
zvaničnom sajtu <a href='http://www.stayclean.rs'>Stayclean.rs</a> pružila Komisija za borbu protiv narkomanije u školama.
Pitanje „Da li znaš odakle dolazi tvoja roba?“, na hrabar i intrigantan način skreće
pažnju javnosti na štetne posledice upotrebe narkotika, ali i na krajnje nehigijensko
krijumčarenje zbog koje bi svako trebalo da se zapita da li je to nešto što zaista želi u
svom životu. Kampanja nudi brojne korisne i krajnje razumljive informacije o štetnom
dejstvu različitih narkotika po mentalno i fizičko zdravlje, ali i brojne druge sadržaje
među kojima su i zabavne video igrice koje prikazuju u kakvim se sve delovima tela,
krajnje nehigijenski prenose narkotici.
        </p>,
        <p>
            Prema podacima istraživanja, u Srbiji je registrovano preko petsto zavisnika od opijata
koji su započeli lečenje. Prosečan uzrast kada se počinje sa eksperimentisanjem sa
drogama, najčešće marihuanom, jeste 16 godina, a prosečan uzrast kada se počinje sa
korišćenjem heroina jeste 21 godina. Zabrinjava i podatak iz poslednjeg istraživanja,
prema kojem je u Srbiji evidentirano 89 smrtnih slučajeva povezanih sa upotrebom
droge, a epidemiološki podaci ukazuju da je prosečan uzrast heroinskih zavisnika koji
su na lečenju oko 33 godine. Zbog toga je cilj #StayClean kampanje da deluje
preventivno, pre svega na mlade koji još uvek nisu postali konzumenti narkotika, a da
one koji to jesu, odvrati od upotrebe droga pre nego što postanu deo mračne statistike.
        </p>,
        <p>
            EXIT fondacija, kao pokretač ove inovativne borbe za čist život bez narkotika, na
samom festivalu ponudiće brojne sadržaje iz #StayClean kampanje za sve svoje
posetioce. Tako će se EXIT, pre svega mladima, obratiti na jedan neposredan način,
koji ih neće odbiti od ove važne društvene teme, već naprotiv, zainteresovati da se
informišu i da na kraju, kako to kampanja poručuje, ostanu potpuno čisti.
        </p>
    ]
};


class About extends Component {

    constructor(props) {
        super(props);

        this.state = {
            url: props.location.state && props.location.state.url ? props.location.state.url : '/',
        }

        this.props.displayHeader(false);
    }

    changeContent(section) {
        this.setState({
            section: section
        });
    }

    render() {

        const lng = strings.getLanguage();

        return (
            <div id="about-page" className="page">
                
                <Link to={'/'}>
                    <img className='logo' src={'images/exit-logo.jpg'} /> 
                </Link>
                
                
                <Link to={this.state.url}>
                    <img className='arrow' src={'images/arrow.png'} />   
                </Link>

                <div className='container'>
                    <h1>{ strings.about.title }</h1>

                    <div className='content'>
                        <ScrollArea speed={0.8} smoothScrolling>
                            { Content[lng] }
                        </ScrollArea>
                    </div>
                </div>

                <FacebookShareButton url={ this.state.url }>
                            <a className='share'>{ strings.footer.share }</a>
                </FacebookShareButton>

                <div className='logos'>
                    <a href='https://www.exitfondacija.org/' target='_blank'>
                        <img src={'images/exit-fondacija.png'} />
                    </a>
                    <a href='https://mts.rs/O-Telekomu' target='_blank'>
                        <img src={'images/telekom.png'} />
                    </a>
                    <a href='https://www.zdravlje.gov.rs/' target='_blank'>
                        <img src={'images/vlada.png'} style={{ height: '90px' }}/>
                    </a>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        displayHeader: Actions.displayHeader
    }, dispatch);
}

function mapStateToProps({ siteDataReducers })
{
    return { siteData: siteDataReducers };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(About));