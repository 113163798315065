import React, {Component} from 'react'
import { render } from "react-dom";
import range from "lodash.range";
import { scaleLinear } from "d3-scale";
import { interpolateInferno } from "d3-scale-chromatic";
import { easeElastic } from "d3-ease";
import { NodeGroup } from "react-move";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import * as Actions from "../actions/Actions";
import Sound from 'react-sound';
import {getRandom} from "../util/Random";
import Step4Sound from './Step4Sound';


class Step4 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cursorX: 0,
            cursorY: 0,
            x: 960,
            y: 600,
            lines: [],
            showHair: true,
            startAnimation: false,
            hairAnimationStart: false,
            isMobile: window.innerWidth < 992,
            pills: []
        }

        this.props.displayHeader(true);

        this.onMouseMove = this.onMouseMove.bind(this);
        this.onFocus = this.onFocus.bind(this);
    }

    componentDidMount() {

        window.addEventListener("focus", this.onFocus)

        if(this.state.isMobile) {
            window.addEventListener("click", this.onMouseMove)
        }
        else {
            window.addEventListener("mousemove", this.onMouseMove)
        }
    }

    componentWilUnmount() {

        window.removeEventListener("focus", this.onFocus)

        if(this.state.isMobile) {
            window.removeEventListener("click", this.onMouseMove)
        }
        else {
            window.removeEventListener("mousemove", this.onMouseMove)
        }
    }

    onFocus() {
        this.setState({
            startAnimation: false,
            lines: [],
            hairAnimationStart: false 
        })
    }

    getRadnomPill() {
        return 'pill-' + getRandom(1, 6);
    }

    onMouseMove(e) {

        let canAnimate = false;

        if(!this.state.showHair) {
            this.setState({ cursorX: e.clientX, cursorY: e.clientY});
        }
        else {

            let lines = [];

            const innerHeight = window.innerHeight * .5;

            if(this.state.cursorY > innerHeight && this.state.cursorY < (innerHeight + 150)) {
                lines = this.generateLines();

                canAnimate = !this.state.startAnimation;

                if(!this.state.hairAnimationStart) {
                    this.setState({
                        hairAnimationStart: true
                    })
                }
            }
            else {
                this.setState({
                    hairAnimationStart: false
                })
            }

            this.setState({ 
                cursorX: e.clientX,
                cursorY: e.clientY,
                lines: lines,
            });

            if(canAnimate) {
                this.setState({ 
                    cursorX: e.clientX,
                    cursorY: e.clientY,
                    lines: lines,
                    
                    startAnimation: true,
                    currentPill: this.state.startAnimation ? this.state.currentPill : this.getRadnomPill()
                 }, () => {

                    let pill = {
                        pill: this.state.currentPill,
                        x: getRandom((window.innerWidth / 2) - 300,  (window.innerWidth / 2) + 300),
                        y: getRandom(window.innerHeight - 200, window.innerHeight - 50)
                    }
    
                    setTimeout(() => {

                        const halfWidth = window.innerWidth / 2;

                        const random = this.state.cursorX >  halfWidth ?
                        getRandom(halfWidth, window.innerWidth) : getRandom(halfWidth, window.innerWidth) 
    
                        this.setState({
                            x: pill.x,
                            y: pill.y
                        })
                    }, 500)

                    setTimeout(() => {

                        let pills = this.state.pills;
                        pills.push(pill);

                        this.setState({
                            startAnimation: false,
                            x: window.innerWidth / 2,
                            y: window.innerHeight / 2,
                            pills: pills
                        })
                    }, 1000)
                 });
            } 
        }
    }

    generateLines() {

        const n = this.state.isMobile ? 116 : 244;
        let lines = [];

        const offsetY = this.state.isMobile ? 60 : 120;

        const startY = this.state.cursorY - offsetY;

        for(let i = 0; i < n; i+= getRandom(1, 10)) {

            const height = getRandom(1, 3)
            const half = window.innerWidth / 2;

            if(this.state.cursorX > half) {
                lines.push(
                    <div style={{ 
                        left: half + 'px', top: (startY + i) + 'px', zIndex: 10000,
                        position: 'fixed', width: (this.state.cursorX - (half + 33)) + 'px', height: height + 'px', background: '#000000'
                    }}>
    
                    </div>
                )
            }
            else {
                lines.push(
                    <div style={{ 
                        left: this.state.cursorX + 'px', top: (startY + i) + 'px', zIndex: 10000,
                        position: 'fixed', width: (half - this.state.cursorX + 0) + 'px', height: height + 'px', background: '#000000'
                    }}>
    
                    </div>
                )
            }
        }

        return <div>{lines}</div>
    }

    getPointerClass() {
        return (this.state.cursorX > window.innerWidth / 2 ? 'pointer pointer-left' : ' pointer pointer-right');
    }

    onContextMenu(e) {
        e.preventDefault();
    }

    renderPills() {

        let result = [];

        for(let pill of this.state.pills) {
            result.push(
                <div key={'pill-' + result.length} className={'pill ' + pill.pill} style={{ position: 'fixed', left: pill.x, top: pill.y, zIndex: 99999999 }}>

                </div>
            );
        }

        return result;
    }

    render() {

        const offsetX = this.state.isMobile ? 35 : 70;
        const offsetY = this.state.isMobile ? 61 : 122;

        return (
            <div id="step4-page" className="page">

            { this.renderPills() }

            <Step4Sound start={ this.state.hairAnimationStart }/>

            { this.state.lines }

            <img onContextMenu={ (e) => this.onContextMenu(e) } src={'/images/body/body.png'} className='body' draggable="false"/>
            <img onContextMenu={ (e) => this.onContextMenu(e) } src={'/images/body/body-2.png'} className='body body2' draggable="false"/>

            <img onContextMenu={ (e) => this.onContextMenu(e) } src={'/images/body/pointer.png'} className={ this.getPointerClass() } draggable="false"
            style={{ left: (this.state.cursorX - offsetX )+ 'px', top: (this.state.cursorY - offsetY) + 'px' }}
            />

            {
                this.state.startAnimation &&
                <NodeGroup
                        data={((range(1).map(d => {
                        return {
                            key: `key-${d}`,
                            x: this.state.x,
                            y: this.state.y
                        };
                        })))}
                        keyAccessor={d => d.key}
                        start={data => {
                        return { x: data.x, y: data.y };
                        }}
                        update={(data, index) => {
                        return {
                            x: [data.x],
                            y: [data.y],
                            timing: {
                            delay: index * 10,
                            duration: 2000,
                            ease: easeElastic
                            }
                        };
                        }}
                    >
                        {nodes => (
                        <div className="animation-container">
                            {nodes.map(({ key, data, state: { x, y } }, index) => (
                            <div className={'pill ' + this.state.currentPill}
                                key={key}
                                style={{
                                WebkitTransform: `translate3d(${x}px, ${y}px, 0)`,
                                transform: `translate3d(${x}px, ${y}px, 0)`,
                                zIndex: 99999999999999999999999999999999999
                                }}
                            />
                            ))}
                        </div>
                        )}
                    </NodeGroup>
            }
                
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        displayHeader: Actions.displayHeader
    }, dispatch);
}

function mapStateToProps({ siteDataReducers })
{
    return { siteData: siteDataReducers };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step4));