import React, {Component} from 'react'
import { render } from "react-dom";
import range from "lodash.range";
import { scaleLinear } from "d3-scale";
import { interpolateInferno } from "d3-scale-chromatic";
import { easeElastic } from "d3-ease";
import { NodeGroup } from "react-move";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import * as Actions from "../actions/Actions";
import Sound from 'react-sound';
import {getRandom} from "../util/Random";


class StepSoundBlow extends Component {

    constructor(props) {
        super(props);

        this.state = {
            startBlow: false,
            blowDid: false,
        }

        this.props.displayHeader(true);
    }

    componentWillReceiveProps(props) {
        
        if(props.startAudio) {
            setTimeout(() => {
                this.setState({
                    startBlow: props.startAudio,
                });
            }, 200);
        }
        else {
            this.setState({
                startBlow: false,
                blowDid: false
            });
        }
    }

    render() {

        return (
            <div style={{ position: 'absolute' }}>
                <Sound
                    url={'/sounds/blowing.mp3'}
                    playStatus={ (this.state.startBlow && !this.state.blowDid) ? Sound.status.PLAYING : undefined }
                    playFromPosition={2400 /* in milliseconds */}
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        displayHeader: Actions.displayHeader
    }, dispatch);
}

function mapStateToProps({ siteDataReducers })
{
    return { siteData: siteDataReducers };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StepSoundBlow));