import React, {Component} from 'react'
import { render } from "react-dom";
import range from "lodash.range";
import { scaleLinear } from "d3-scale";
import { interpolateInferno } from "d3-scale-chromatic";
import { easeElastic } from "d3-ease";
import { NodeGroup } from "react-move";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import * as Actions from "../actions/Actions";
import {getRandom} from "../util/Random";
import Sound from 'react-sound';
import strings from '../localization';
import Step1Sound from './Step1Sound';

class Step1 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            startAnimation: false,
            x: window.innerWidth / 2,
            y: 0,
            currentPill: '',
            width: window.innerWidth,
            height: window.innerHeight,
            pills: [],
            showWind: false,
            startAudio: false,
            cursorX: 0,
            cursorY: 0,
            assAnimationm: false,
            showShadow: false
        }

        this.props.displayHeader(true);
    }

    getRadnomPill() {
        return 'pill-' + getRandom(1, 6);
    }

    renderPills() {

        let result = [];

        for(let pill of this.state.pills) {
            result.push(
                <div key={'pill-' + result.length} className={'pill ' + pill.pill} style={{ position: 'fixed', left: pill.x, top: pill.y }}>

                </div>
            );
        }

        return result;
    }

    assClick(e) {

        if(this.state.startAnimation || this.state.assAnimationm) {
            return;
        }

        const assRandom = getRandom(0, 100);

        if(assRandom < 30) {

            this.setState({
                assAnimationm: true
            });

            setTimeout(() => {
                this.setState({
                    assAnimationm: false
                }); 
            }, 2000);

            return;
        }

        this.setState({
            showShadow: true
        });

        setTimeout(() => {
            this.setState({
                showShadow: false
            });
        }, 80);

        const newX = window.innerWidth / 2 + getRandom(-200, 200);
        const newY = window.innerHeight - 200 + getRandom(-100, 100);
        const currentPill = this.getRadnomPill();
        let pills = JSON.parse(JSON.stringify(this.state.pills));

        pills.push({
            x: newX,
            y: newY,
            pill: currentPill,
        });

        this.setState({
            startAnimation: true,
            assAnimationm: true,
            currentPill: currentPill,
            startAudio: true,
            cursorX: e.clientX,
            cursorY: e.clientY
        }, () => {

            setTimeout(() => {
                this.setState({
                    x: newX,
                    y: newY,
                    showWind: true,
                    fartSoundStatus: Sound.status.PLAYING
                })
            }, 500);
        });

        setTimeout(() => {
            this.setState({
                showWind: false,
                pillSoundStatus: Sound.status.PLAYING
            })
        }, 650);

        setTimeout(() => {
            this.setState({
                startAnimation: false,
                assAnimationm: false,
                x: window.innerWidth / 2,
                y: 0,
                pills: pills,
                startAudio: false
            }); 
        }, 2000);
    }

    onContextMenu(e) {
        e.preventDefault();
    }

    render() {
        return (
            <div id="step1-page" className="page">

                <Step1Sound startAudio={ this.state.startAudio } />
  
                <div className='hand-mark'  style={{
                    background: 'radial-gradient(circle closest-side, #9e9e9e, transparent)',
                    width: '50px',
                    height: '50px',
                    position: 'fixed',
                    opacity: this.state.showShadow ? '.3' : '0',
                    left: this.state.cursorX + 20,
                    top: this.state.cursorY - 20,
                    zIndex: 999999999999999,
                }}></div>
                

                <div className="ass">

                    <img onContextMenu={ (e) => this.onContextMenu(e) } src={'images/ass.png'} className={ !this.state.assAnimationm ? 'vibrate-1 ass-image' : 'jello-horizontal ass-image' }
                      onClick={(e) => this.assClick(e)}/>

                <img onContextMenu={ (e) => this.onContextMenu(e) } src={'images/ass-mobile.png'} className={ !this.state.assAnimationm ? 'vibrate-1 ass-image-mobile' : 'jello-horizontal ass-image-mobile' }
                      onClick={(e) => this.assClick(e)}/>

                      <img src={'images/wind.png'} className={'wind ' + (this.state.showWind ? 'show' : '')}/>
                </div>

            
                {
                    this.renderPills()
                }

                {
                    this.state.startAnimation &&
                    <NodeGroup
                        data={((range(1).map(d => {
                        return {
                            key: `key-${d}`,
                            x: this.state.x,
                            y: this.state.y
                        };
                        })))}
                        keyAccessor={d => d.key}
                        start={data => {
                        return { x: data.x, y: data.y };
                        }}
                        update={(data, index) => {
                        return {
                            x: [data.x],
                            y: [data.y],
                            timing: {
                            delay: index * 120,
                            duration: 2000,
                            ease: easeElastic
                            }
                        };
                        }}
                    >
                        {nodes => (
                        <div className="animation-container">
                            {nodes.map(({ key, data, state: { x, y } }, index) => (
                            <div className={'pill ' + this.state.currentPill}
                                key={key}
                                style={{
                                WebkitTransform: `translate3d(${x}px, ${y}px, 0)`,
                                transform: `translate3d(${x}px, ${y}px, 0)`,
                                zIndex: nodes.length - index + 5000
                                }}
                            />
                            ))}
                        </div>
                        )}
                    </NodeGroup>
                }

            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        displayHeader: Actions.displayHeader
    }, dispatch);
}

function mapStateToProps({ siteDataReducers })
{
    return { siteData: siteDataReducers };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step1));