import React, {Component} from 'react'
import Link from 'react-router-dom/Link';
import strings from '../localization';
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import { FacebookShareButton } from "react-share";

class Footer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            url: props.url ? props.url : window.location.href
        }
    }

    getNext() {

        if(this.props.location.pathname === '/step1') {
            return '/step2';
        }
        if(this.props.location.pathname === '/step2') {
            return '/step3';
        }
        if(this.props.location.pathname === '/step3') {
            return '/step4';
        }
        if(this.props.location.pathname === '/step4') {
            return '/step1';
        }

        return '#';
    }

    render() {

        if(!this.props.siteData.header){
            return '';
        }

        return (
               <footer id='footer'>
                    <div className='left'>
                        <Link to={this.getNext()} className='btn'>{ strings.footer.showMe }</Link>
                    </div>
                   
                    <div className='right'>
                        <Link to={{ pathname: '/about', state: { url: this.props.location.pathname }  }}>{ strings.footer.about }</Link>
                        <Link className='worning' to={{ pathname: '/now-seriously', state: { url: this.props.location.pathname }  } }><img src={'/images/worning.png'}/>{ strings.footer.nowSerius }</Link>
                        <FacebookShareButton url={ this.state.url }>
                            <a >{ strings.footer.share }</a>
                        </FacebookShareButton>
                    </div>
               </footer>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
    }, dispatch);
}

function mapStateToProps({ siteDataReducers })
{
    return { siteData: siteDataReducers };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));