import React, {Component} from 'react'
import strings from '../localization';
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import Link from 'react-router-dom/Link';

class Header extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        if(!this.props.siteData.header){
            return '';
        }

        return (
            <div id="header">
                <div className="left">
                    {/* <h3 className="title1">{ strings.header.title1 }</h3>
                    <h3 className="title2">{ strings.header.title2 }</h3>
                    <h3 className="title3">{ strings.header.title3 }</h3> */}

                    <img src={'images/header-slogan.png'}/>
                </div>

                <div className="right">
                    <Link to={'/'}>
                        <img src={'images/exit-logo.svg'}/>
                        <h3>{ strings.header.stayclean }</h3>
                    </Link>
                
                </div>
            </div>
            
        );
    }
}
function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
    }, dispatch);
}

function mapStateToProps({ siteDataReducers })
{
    return { siteData: siteDataReducers };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));