import React, {Component} from 'react'
import { render } from "react-dom";
import range from "lodash.range";
import { scaleLinear } from "d3-scale";
import { interpolateInferno } from "d3-scale-chromatic";
import { easeElastic } from "d3-ease";
import { NodeGroup } from "react-move";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import * as Actions from "../actions/Actions";
import Sound from 'react-sound';
import {getRandom} from "../util/Random";


class Step4Sound extends Component {

    constructor(props) {
        super(props);

        this.state = {
            start: false,
            startPill: false
        }

        this.props.displayHeader(true);
    }

    componentWillReceiveProps(props) {
        
        this.setState({
            start: props.start,
        })
    }


    render() {

        return (
            <div>
                <Sound
                    url={'/sounds/hair.mp3'}
                    playStatus={ this.state.start ? Sound.status.PLAYING : undefined }
                    playFromPosition={0 /* in milliseconds */}
                />
                <Sound
                    url={'/sounds/pills2.mp3'}
                    playStatus={ this.state.start ? Sound.status.PLAYING : undefined }
                    playFromPosition={0 /* in milliseconds */}
                    loop={true}
                />
            </div>
                
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        displayHeader: Actions.displayHeader
    }, dispatch);
}

function mapStateToProps({ siteDataReducers })
{
    return { siteData: siteDataReducers };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step4Sound));