import React from 'react'
import LocalizedStrings from 'react-localization';


let strings = new LocalizedStrings({

    en: {

        home: {
            titleEn1: "Do you know",
            titleEn2: "where your drugs",
            titleEn3: "come from?",
            titleSr1: "Da li znaš",
            titleSr2: "odakle dolazi",
            titleSr3: "tvoja roba?",
            buttonEn: "Show me",
            buttonSr: "POKAŽI MI"
        },

        about: {
            title: 'About #StayClean'
        },

        header: {
            stayclean: '#STAYCLEAN',
            title1: 'Do you know',
            title2: 'where your drugs',
            title3: 'come from?'
        },

        footer: {
            showMe: 'Show me more',
            nowSerius: 'Now SERIOUSLY',
            share: 'Share',
            about: 'About'
        },

        nowSeriously: {
            title: 'Now Seriously #STAYCLEAN',
            
            sideBar: {
                moreOnSpecificDrugs: 'More On Specific Drugs',
                negativeEffectsOfDrugs: 'Negative Effects Of Drugs',
                Amphetamines: 'Amphetamines',
                Marijuana: 'Marijuana',
                Ecstasy: 'Ecstasy',
                Heroin: 'Heroin',
                Cocaine: 'Cocaine',
                Steroids: 'Steroids',
                NewPsychoactiveSubstances: 'Synthetic cannabinoids',
                Hallucinogens: 'Hallucinogens',
                Fentanil: 'Fentanil'
            },
        },

    },

    rs: {

        home: {
            titleEn1: "Do you know",
            titleEn2: "where your drugs",
            titleEn3: "come from?",
            titleSr1: "Da li znaš",
            titleSr2: "odakle dolazi",
            titleSr3: "tvoja roba?",
            buttonEn: "Show me",
            buttonSr: "POKAŽI MI"
        },

        about: {
            title: 'O #StayClean'
        },

        header: {
            stayclean: '#STAYCLEAN',
            title1: 'Da li znaš',
            title2: 'odakle dolazi',
            title3: 'tvoja roba?'
        },

        nowSeriously: {
            title: 'A sada ozbiljno #STAYCLEAN',
            
            sideBar: {
                moreOnSpecificDrugs: 'Više o specifičnim drogama',
                negativeEffectsOfDrugs: 'Negativni uticaj droge',
                Amphetamines: 'Amfetamini',
                Marijuana: 'Marihuana',
                Ecstasy: 'Ekstazi',
                Heroin: 'Heroin',
                Cocaine: 'Kokain',
                Steroids: 'Sintetički kanabinoidi',
                NewPsychoactiveSubstances: 'Sintetički kanabinoidi',
                Hallucinogens: 'Halucogeni',
                Fentanil: 'Fentanil'
            },
        },

        footer: {
            showMe: 'Pokaži mi još',
            nowSerius: 'Sada ozbiljno',
            share: 'Podeli',
            about: 'O nama'
        }
    }
});

export default strings;