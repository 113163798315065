import React, {Component} from 'react'
import { render } from "react-dom";
import range from "lodash.range";
import { scaleLinear } from "d3-scale";
import { interpolateInferno } from "d3-scale-chromatic";
import { easeElastic } from "d3-ease";
import { NodeGroup } from "react-move";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import * as Actions from "../actions/Actions";
import Sound from 'react-sound';
import {getRandom} from "../util/Random";
import Step3Sound from './Step3Sound';
import StepSoundBlow from './StepSoundBlow';

class Step3 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            state: 'idle',
            blask: '',
            count: 0,
            playSound: false,
            playSoundBlow: false,
            blowoutStart: false
        }

        this.props.displayHeader(true);
    }

    boom() { 

        this.state.count = this.state.count + 1;

        if(this.state.count < 6) {
            
            this.setState({
                playSoundBlow: true
            });

            setTimeout(() => {
                this.setState({
                    playSoundBlow: false
                }); 
            }, 1000);
            
            return;
        }

        this.setState({
            state: 'shake',
        });

        if(this.state.count < 10) {

            setTimeout(() => {

                if(this.state.count < 10) {
                    this.setState({
                        count: 0,
                        blowoutStart: true,
                        playSoundBlow: false
                    });

                    setTimeout(() => {
                        this.setState({
                            state: 'idle',
                        });
                    },600)

                    setTimeout(() => {
                        this.setState({
                            blowoutStart: false
                        })
                    }, 1500)
                }
            }, 2500);
            return;
        }

        setTimeout(() => {
            this.setState({
                state: 'boom',
                blowingSoundStatus: undefined,
                playSound: true,
            })
        }, 2500);

        setTimeout(() => {
            this.setState({
                blask: 'action', 
            })
        }, 3400);

        setTimeout(() => {
            this.setState({
                state: 'idle',
            })
        }, 4400);

        setTimeout(() => {
            this.setState({
                playSound: false,
                blask: '',
                count: 0
            })
        }, 6000);
    }

    getAssClass() {

        if(this.state.state === 'idle') {
            return 'scale-' + this.state.count;
        }
        else if(this.state.state === 'shake') {
            return 'shake';
        }
        else {
            return 'ass-boom';
        }
    }

    onContextMenu(e) {
        e.preventDefault();
    }

    render() {
        return (
            <div id="step3-page" className="page">

                <Step3Sound startAudio={ this.state.playSound } startBlowout= {this.state.blowoutStart}/>
                <StepSoundBlow startAudio={ this.state.playSoundBlow }/>
                
                
                <div className='container'>
                    <div className={ 'firework image-background ' + (this.state.state === 'boom' ? 'action' : '')}/>
                    <div className={ 'dirt image-background ' + (this.state.state === 'boom' ? 'action' : '')}/>
                    <div className={ 'dirt2 image-background ' + (this.state.state === 'boom' ? 'action' : '')}/>
                    <div className={ 'boom image-background ' + (this.state.state === 'boom' ? 'action' : '')}/>
                    <div className={ 'pills image-background ' + (this.state.state === 'boom' ? 'action' : '')}/>
                    <div className={ 'joints image-background ' + (this.state.state === 'boom' ? 'action' : '')}/>
                    <img src={'images/boom/ass.png'} onClick={ () => this.boom() } className={ this.getAssClass() }/>
                    <div className={ 'blask ' + this.state.blask }/>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        displayHeader: Actions.displayHeader
    }, dispatch);
}

function mapStateToProps({ siteDataReducers })
{
    return { siteData: siteDataReducers };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step3));