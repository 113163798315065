import React, {Component} from 'react'
import strings from '../localization';
import {Link} from "react-router-dom";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import * as Actions from "../actions/Actions";

class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            animate: ''
        }

        this.props.displayHeader(false);
    }

    componentDidMount() {

        setTimeout(() => {
            this.setState({
                animate: ' animate bounceOutLeft'
            })
        }, 1000);

    }

    goNext(lng) {
        strings.setLanguage(lng);
        this.props.history.push('/step1');
    }

    render() {
        return (
            <div id="home-page" className="page">

                <div className= { "section left" }>

                    {/* <h2 className="title1 animated bounceInUp delay-0s">{ strings.home.titleEn1 }</h2>
                    <h2 className="title2 animated bounceInUp delay-1s">{ strings.home.titleEn2 }</h2>
                    <h2 className="title3 animated bounceInUp delay-2s">{ strings.home.titleEn3 }</h2> */}

                    <img className="bounceInUp delay-0s" src={'images/roba-en.png'}/>

                    <a onClick={ () => this.goNext('en') } className="btn bounceInUp delay-1s">{ strings.home.buttonEn }</a>

                </div>

                <div className="section right">
                   
                    {/* <h2 className="title1 animated bounceInUp delay-0s">{ strings.home.titleSr1 }</h2>
                    <h2 className="title2 animated bounceInUp delay-1s">{ strings.home.titleSr2 }</h2>
                    <h2 className="title3 animated bounceInUp delay-2s">{ strings.home.titleSr3 }</h2> */}

                    <img className=" bounceInUp delay-0s" src={'images/roba-sr.png'}/>

                    <a onClick={ () => this.goNext("rs") } className="btn bounceInUp delay-1s">{ strings.home.buttonSr }</a>
                </div>

            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        displayHeader: Actions.displayHeader
    }, dispatch);
}

function mapStateToProps({ siteDataReducers })
{
    return { siteData: siteDataReducers };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));