import React, {Component} from 'react'
import { render } from "react-dom";
import range from "lodash.range";
import { scaleLinear } from "d3-scale";
import { interpolateInferno } from "d3-scale-chromatic";
import { easeElastic } from "d3-ease";
import { NodeGroup } from "react-move";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import * as Actions from "../actions/Actions";
import {getRandom} from "../util/Random";
import Sound from 'react-sound';

class Step2 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            hoverEffectLeft: '',
            hoverEffectRight: '',
            mumlanjeSoundStatus: undefined,
            soundPosition: 0,
            started: false
        }

        this.props.displayHeader(true);
    }

    mouseEnter(target) {

        if(this.state.started) {
            return;
        }

        this.state.started = true;

        this.setState({
            [target]: 'wobble-hor-bottom' + getRandom(1, 4),
            mumlanjeSoundStatus: Sound.status.PLAYING
        });

        const random = getRandom(1000, 2000);

        this.state.soundPosition += this.state.soundPosition > 46000 ? 0 : random;

        setTimeout(() => {
            this.mouseLeave(target);
        }, random)
    }

    mouseLeave(target) {
        this.setState({
            [target]: '',
            started: false,
            mumlanjeSoundStatus: undefined
        });
    }

    onContextMenu(e) {
        e.preventDefault();
    }

    render() {

        const random = getRandom(100, 1000);

        return (
            <div id="step2-page" className="page">

                <Sound
                    url={'/sounds/mumlanje.mp3'}
                    playStatus={this.state.mumlanjeSoundStatus}
                    playFromPosition={this.state.soundPosition /* in milliseconds */}
                    loop={true}
                />

                <div className='container'>
                    <div className='left'>
                        <img src={'images/sneakers/tongue-left.png'} className={ 'tongue ' + this.state.hoverEffectLeft }
                         onClick={() => this.mouseEnter('hoverEffectLeft')}
                         onContextMenu={ (e) => this.onContextMenu(e) }
                         />
                    </div>
                    <div className='right'>
                        <img src={'images/sneakers/tongue-right.png'} className={ 'tongue ' + this.state.hoverEffectRight }
                         onClick={() => this.mouseEnter('hoverEffectRight')}
                         onContextMenu={ (e) => this.onContextMenu(e) }
                        />
                    </div>

                    <img src={'images/sneakers.png'} className='sneakers'/>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        displayHeader: Actions.displayHeader
    }, dispatch);
}

function mapStateToProps({ siteDataReducers })
{
    return { siteData: siteDataReducers };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step2));