import React from 'react';
import Home from './pages/Home';
import Error from "./pages/Error";
import Forbidden from "./pages/Forbidden";
import NotFound from "./pages/NotFound";

import { Route } from 'react-router-dom';
import { isUserLoggedIn } from "./base/OAuth";
import Step1 from './pages/Step1';
import Step2 from './pages/Step2';
import Step3 from './pages/Step3';
import Step4 from './pages/Step4';
import NowSeriously from './pages/NowSeriously';
import About from './pages/About';

let ROUTES = {
    Home: {
        path: '/',
        component: <Home/>,
        auth: false
    },
    Step1: {
        path: '/step1',
        component: <Step1/>,
        auth: false
    },
    Step2: {
        path: '/step2',
        component: <Step2/>,
        auth: false
    },
    Step3: {
        path: '/step3',
        component: <Step3/>,
        auth: false
    },
    Step4: {
        path: '/step4',
        component: <Step4/>,
        auth: false
    },
    NowSeriously: {
        path: '/now-seriously',
        component: <NowSeriously/>,
        auth: false
    },
    About: {
        path: '/about',
        component: <About/>,
        auth: false
    }
};

export default ROUTES;

function getRoute(path) {

    for(const [key, value] of Object.entries(ROUTES)) {

        if(value.path === path) {
            return value;
        }
    }

    return null;
}

export function checkPath(path) {

    let pathObject = getRoute(path);

    if(!pathObject) {
        return true;
    }

    if(pathObject.auth) {
        return !isUserLoggedIn();
    }

    return false;
}

export function getRoutes() {

    let result = [];

    for(const [key, value] of Object.entries(ROUTES)) {

        result.push(
            <Route key={ 'route-' + result.length } exact path={ value.path } render={() => (
                value.component
            )}/>
        )
    }

    return result;
}