import React, {Component} from 'react'
import strings from '../localization';
import {Link} from "react-router-dom";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import * as Actions from "../actions/Actions";
import { FacebookShareButton } from "react-share";
import ScrollArea from 'react-scrollbar';

const Content = {
    en: {
        negativeEffectsOfDrugs: 
            (
                <div className='text'>
                    <h2>Negative Effects Of Drugs</h2>
                        <ScrollArea speed={0.8} smoothScrolling>
                        <div className='column'>
                            <h3>BRAIN CHEMISTRY</h3>
                            <p>
                            Brain is the most complex organ in your body and it regulates the living processes of the entire organism with its direct physical and mental influence. This is the very reason why your brain is the most responsible for who you really are, with all your feelings, ambitions, desires and memories. Drugs and alcohol shape the way you feel, directly changing the chemical processes that interfere with brain functions and transform you as a person, both mentally and physically. 
                            </p>
                            <p>
                            How does this happen? Our brain naturally releases dopamine, a substance associated with euphoria, motivation and numerous other positive effects. Under the influence of drugs dopamine is freely released, which leads to euphoria and the desire to repeat this feeling.
                            </p>
                            <p>
                            Nature has already set us up in such a way so as to have regular “shipments” of dopamine, however, what happens when we teach our brain to work “overtime” and deliver large amounts of this substance? By repeating the intake, the brain gets used to the sudden release of dopamine and demand more, which brings a list of dangerous effects on your body and mind. In other words, our body and consciousness is changed outside of our will and control.
                            </p>
                            <h3>HEALTH COMPLICATIONS</h3>
                            <p>
                                Droga i alkohol utiču na delove tela. Samo neke od posledica jesu opšta slabost,
                                grčevi mišića, poremećaj rada srca, poremećaj krvotoka, oštećenje bubrega i jetre,
                                kao i smrtni ishod.
                            </p>
                            <p>
                            Drugs and alcohol influence parts of your body. Some of the consequences include general weakness, muscle cramps, heart arrhythmia, blood flow problems, kidney and liver defects, as well as death.
                            </p>
                            <p>
                            In Serbia in 2017 there was 512 new drug addicts registered, starting their treatment. Average age of starting to experiment with drugs (usually marijuana) is 16, and the average age of starting to use heroin is 21. Epidemiological data shows that the average age of heroin addicts is 33.2. This means that 10 years pass on average from starting to use heroin until going into treatment! This is exactly the period when addicts exhibit risky behavior, with consequences such as infectious diseases connected to the use of drugs, as well as mental defects that could be permanent. 
                            </p>
                            <h3>INFECTIOUS DISEASE</h3>
                            <p>
                            The influence of drugs and alcohol on your body has consequences in various areas of your life. It shows in everyday behavior, poor family and peer relationships, leads to risky behavior where people share needles and syringes, which in turn leads to an increase in the risk of hepatitis B and C, as well as HIV. In 2017, there were 1,046 new cases of HIV in the EU.
                            </p>
                            <p>
                            In Serbia, based on the data on infectious diseases related to drug abuse, the greatest percentage of intravenous drug users has hepatitis C (44.8%), hepatitis B (4.6 %) and HIV (1.4%).
                            </p>
                            <p>
                            Danger from infection does not come only from HIV and hepatitis, but from the contamination of drugs with some infectious agents. There have been found cases around the world of salmonella, botulin exotoxin, even anthrax present in drugs due to contamination. These are serious infectious diseases that can lead to death.
                            </p>
                        </div>
                        <div className='column'>
                            <h3>INJURIES AND DEATH RATE</h3>
                            <p>
                            By using drugs and alcohol we put ourselves and others in danger, increasing the risk of physical injuries and traffic accidents. 
                            </p>
                            <p>
                            It is very worrying to learn that there were 89 cases of death related to drugs in Serbia, in 2017. The main cause of death was overdose, however, there were three cases of suicide, at the ages of 18, 22, and 29, with THC (the psychoactive component of cannabis) present in their blood.
                            </p>
                            <p>
                            New psychoactive substances, including synthetic opioids, synthetic cannabinoids and synthetic cathinones, are connected to cases of death and acute poisonings in Europe. Especially worrying are fentanyl derivatives which make up most of new opioids. These substances are extremely powerful and very small amounts can cause poisoning that is extremely dangerous due to a fast and heavy respiratory depression. In 2018 almost every week saw a new registered substance, with a larger number of synthetic opioids and benzodiazepines. 
                            </p>
                            <h3>LEGAL CONSEQUENCES</h3>
                            <p>
                            By using drugs and alcohol we bring ourselves into a position where we might need to face legal consequences of such actions. Due to the reduction in attention and the change of priorities when it comes to daily activities, the ability to find work, difficulty in securing financial means for life, being stigmatized or losing trust at work, we are, by ourselves and others, slowly excluded from society, creating constantly deteriorating conditions for us and our family. 
                            </p>
                            <p>
                            Crime is firmly connected to drugs. Many addicts have problems with the law and the data shows that 30.3% of them have been to prison due to crimes in connection with drugs.
                            </p>
                        </div>
                    </ScrollArea>
                </div>
            ),
            Amphetamines: 
            (
                <div className='text'>
                    <h2>AMPHETAMINES</h2>
                        <ScrollArea speed={0.8} smoothScrolling>
                        <div className='column'>
                            <h3>WHAT ARE AMPHETAMINES?</h3>
                            <p>
                            Amphetamines are synthetic drugs that most commonly appear in the form of a white powder that acts as a stimulant to the central nervous system. The most common form of amphetamine is a white or colorless powder soluble in water. Tablets containing amphetamine carry logos similar to those found on MDMA and other ecstasy tablets.

Street names are “speed”, “base” and “whizz”.
                            </p>
                            <h3>EFFECTS</h3>
                            <p>
                            As a stimulant to the central nervous system, amphetamine causes a sense of increased self-confidence, sociability and energy, followed by an elevated blood pressure and tachycardia. It suppresses appetite and fatigue and leads to insomnia. By oral administration, the action usually begins within 30 minutes and can last for hours. Afterwards, users can feel irritable, restless, upset and depressed.
                            </p>
                        </div>
                        <div className='column'>
                            <h3>RISKS AND HEALTH HAZARDS</h3>
                            <p>
                            Acute intoxication causes serious cardiovascular disorders, as well as behavioral problems that include anxiety, confusion, paranoia, impulsiveness, and violence. The chronic administration of amphetamine causes neurochemical and neuroanatomic changes and results in damage to the central nervous system, resulting in impaired memory and a reduction in the decision-making ability and the ability to make verbal conclusions. Some symptoms resemble paranoid schizophrenia. 
                            </p>
                            <p>
                            Intravenous injection of amphetamine can lead to viral infections (HIV and hepatitis), as is the case with other injectable drugs, such as heroin.
                            </p>
                            <p>
                            In some countries, deaths associated with amphetamine use have also been reported. 
                            </p>
                        </div>
                    </ScrollArea>
                </div>
            ),
            Marijuana: 
            (
                <div className='text'>
                    <h2>MARIJUANA / CANNABIS</h2>
                    <ScrollArea speed={0.8} smoothScrolling>
                        <div className='column'>
                            <h3>WHAT IS MARIJUANA?</h3>
                            <p>
                            Cannabis is a natural product whose main psychoactive ingredient is tetrahydrocannabinol (Δ9-THC). Cannabis plant (Cannabis sativa L.) is widespread and grows in temperate and tropical areas. Herbal cannabis consists of dried flower peaks and leaves. Cannabis resin is a substance made from resinous parts of the plant, and cannabis oil (hashish) is an extract of the cannabis solution.
	Cannabis products and Δ9-THC are internationally controlled.
	Marijuana is usually smoked in the form of hand-rolled cigarettes (joint) or by using pipes or water pipes (bongs). Marijuana smoke has a distinctive sweet-sour smell. 
                            </p>
                            <h3>EFFECTS</h3>
                            <p>
                            When marijuana is smoked, THC rapidly passes from the lungs into the bloodstream. Regardless of the manner of consuming, THC affects the specific receptors in the brain cells called cannabinoid receptors, which are part of the endocannabinoid system in the brain. The highest density of cannabinoid receptors has been found in the brain parts that affect the sense of pleasure, memory, thinking, concentration, touch and time perception and coordination of movement. Marijuana enhances the activity of the endocannabinoid system, causing the feeling of excitement (high) and other effects that users feel. These effects include altered perception and mood, disturbed coordination, problems with thinking, problem-solving, and disrupted learning and memory abilities.
                            </p>
                            <p>
                            Marijuana also affects the development of the brain, and when consumed frequently by young people, the effects on thinking and memory may last longer or may even be permanent. A recent study on marijuana users that began using in puberty revealed significantly reduced connections in areas of the brain responsible for learning and memory. A study in New Zealand showed that young people who consumed marijuana in large amounts during puberty lost an average of 8 points in IQ.
                            </p>
                        </div>
                        <div className='column'>
                            <h3>RISKS AND HEALTH HAZARDS</h3>
                            <p>
                            Marijuana can cause a wide range of adverse effects on physical and mental health. Marijuana smoke irritates the lungs which makes marijuana smokers prone to respiratory problems such as cough and there is an increased risk of pneumonia. It is not yet known whether smoking marijuana contributes to the risk of lung cancer. Marijuana also increases the number of heartbeats shortly after smoking by 20-100 percent, and this effect can last for up to three hours. Marijuana users have a 4.8 times higher risk of heart attack during the first hour after smoking. 
                            </p>
                            <p>
                            Many studies have linked the long-term consumption of marijuana with mental illness. A high dose of marijuana may cause a temporary psychotic reaction in some consumers (including hallucinations and paranoia). Consuming marijuana can aggravate the course of the disease in patients with schizophrenia. A large number of surveys tracking users over a certain period of time indicated a link between the consumption of marijuana and the subsequent development of psychosis. This relationship depends on the amount consumed by the person, the strength of the drug and the age of first consumption - those who started consuming at a younger age are at a greater risk of subsequent psychological problems.
                            </p>
                            <p>
                            Connection has also been discovered between the use of marijuana and other mental problems such as depression, anxiety, suicidal thoughts, and personality disorders including a lack of motivation.
                            </p>
                            <p>
                            Consuming marijuana during pregnancy is associated with an increased risk for the baby. A child may have various problems including problems with attention, memory, and problem solving at a later age. Additionally, marijuana has serious repercussions on assessment skills and motor coordination and contributes to an increased risk of injury or death when driving. 
                            </p>
                        </div>
                    </ScrollArea>
                </div>
            ),
            Ecstasy: 
            (
                <div className='text'>
                    <h2>ECSTASY (MDMA)</h2>
                    <ScrollArea speed={0.8} smoothScrolling>
                        <div className='column'>
                            <h3>WHAT IS ECSTASY?</h3>
                            <p>
                            MDMA (3,4-methylenedioxy-methamphetamine) popularly called “ecstasy” or, as of recently, Molly, is a synthetic psychoactive drug that has similarities with the stimulant amphetamine, but also with hallucinogenic mescaline. It is usually found in the form of a capsule, tablet or powder. It is most often consumed orally or by sniffing. The popular name Molly (slang for “molecular”) refers to pure crystalline powder in the form of MDMA, which is most often sold in capsules. 

Street names are ecstasy, bonbon, bonkas, ex…
                            </p>
                            <h3>EFFECTS</h3>
                            <p>
                            MDMA works on the basis of increasing the activity of three neurotransmitters - serotonin, dopamine and norepinephrine. The emotional and social effects of MDMA are directly or indirectly caused by the release of a large amount of serotonin that affects the mood (as well as other functions such as appetite and sleep). Serotonin also causes the release of oxytocin and vasopressin hormones which play an important role in the feelings of love, trust, and sexual excitement. 
                            </p>
                        </div>
                        <div className='column'>
                            <h3>RISKS AND HEALTH HAZARDS</h3>
                            <p>
                            Disruption in serotonin levels caused by the consumption of MDMA leads to negative effects, such as confusion, depression, sleep problems, craving for drugs and anxiety, which can occur soon after the initial effects of the drug have passed or a few days or even a few weeks later. Some people who consumed MDMA for a long time experienced long-lasting confusion, depression, sleep problems, and problems with attention and memory.
                            </p>
                            <p>
                            MDMA has effects like other stimulants, such as cocaine and amphetamines. These effects include an increased heart rate and high blood pressure, which are particularly risky for people with vascular problems or a heart disease. MDMA users can also experience other symptoms such as muscle tension, uncontrolled teeth grinding, nausea, blurred vision, fainting, trembling and sweating.
                            </p>
                            <p>
                            At high doses, MDMA can affect thermoregulation, which can lead to a sudden increase in temperature (hyperthermia) resulting in liver failure, kidney failure, cardiovascular problems, or even death. 
                            </p>
                            <p>
                            The risk is the fact that tablets and even capsules of supposedly pure ecstasy "Molly" sometimes contain other drugs or supplements. This may include ephedrine (stimulant), ketamine, caffeine, cocaine, methamphetamine, or even the recently synthesized cathinone (a psychoactive ingredient in "bathing soles"). Users who combine this mixture with additional substances such as marijuana or alcohol can suffer an even greater risk of adverse health effects.
                            </p>
                            <p>
                            Negative effects of MDMA consumption are exhaustion, slowed down motion coordination, concentration problems, headache, sleep disorder, nausea with vomiting, hang-overs that can take days and are usually accompanied by severe depression, as well as erectile disfunction. The long-term effect for frequent users includes serious damage to the liver and kidneys. MDMA's effect on increased sexual impulse (especially in combination with sildenafil) can trigger an unprotected sexual relationship that is a risk factor for sexually transmitted diseases, HIV and hepatitis.
                            </p>
                        </div>
                    </ScrollArea>
                </div>
            ),
            Heroin: 
            (
                <div className='text'>
                    <h2>HEROIN</h2>
                    <ScrollArea speed={0.8} smoothScrolling>
                        <div className='column'>
                            <h3>WHAT IS HEROIN?</h3>
                            <p>
                            Heroin is an opiate drug synthesized from a naturally occurring substance extracted from the seed of the Asian opiate plant, poppy. Heroin most commonly appears in the form of a white and brown powder or as a black adhesive substance known as "Black Tar Heroin".
	Heroin is most commonly used by injecting, inhaling, sniffing, or smoking. It causes dependence in the form of a chronic recurrent disease caused by changes in the brain.
                            </p>
                            <h3>EFFECTS</h3>
                            <p>
                            Heroin binds to opioid receptors that are located in the parts of the brain involved in the perception of pain. Opioid receptors are located in the brainstem which controls the automated life-essential processes such as blood pressure and respiration. Heroin overdose often involves respiratory arrest, which can be fatal.
                            </p>
                            <p>
                            After intravenously injecting heroin, there is a sense of euphoria that is followed by: dry mouth, evaporation of heat from the surface of the skin, a feeling of heaviness in the limbs and impaired mental functioning.
                            </p>
                            <p>
                            After the initial euphoria, the user experiences alternates between a wakeful and a drowsy state. Regular heroin consumption leads to changes in brain function. One consequence is the tolerance - more and more drugs are needed to achieve the same intensity of the effect of the drug. The other consequence is the dependence, characterized by the need for continuous drug use in order to avoid symptoms of drug withdrawal.
                            </p>
                        </div>
                        <div className='column'>
                            <h3>RISKS AND HEALTH HAZARDS</h3>
                            <p>
                            Injecting the drug increases the risk of HIV or hepatitis C (HCV) infection, given that these are blood-borne diseases. This can occur when using common drug injectors (needles and syringes). HIV (and to a lesser extent HCV) can also be contracted during unprotected sex, which occurs much more frequently with drug users.
                            </p>
                            <p>
                            Heroin abuse is associated with a number of serious health consequences, including overdose, spontaneous abortion and infectious diseases such as hepatitis and HIV. Chronic users may suffer bursting of the veins, inflammation of the heart and lungs, purulent ulcer, constipation and gastrointestinal cramps, as well as liver and kidney diseases. Complications with the lungs, including various types of pneumonia, can lead to poorer health of consumers, but also to fatal consequences related to breathing and heart function.
                            </p>
                            <p>
                            In addition to the consequences of the drug itself, street heroin often contains toxic compounds and additives that can clog the veins, which can lead to permanent damage to vital organs such as the lungs, liver, kidneys or brain.
                            </p>
                            <p>
                            The chronic use of heroin leads to physical dependence, a state in which the body is already accustomed to the presence of the drug. If a drug user suddenly reduces intake or stops using the drug completely, they can experience different symptoms. These symptoms, which may begin as early as several hours after the last consumption, may include a feeling of restlessness, muscle and bone pain, insomnia, diarrhea and vomiting, sudden feeling of cold and twitching of body parts. Users also feel a great desire for the drug before they consume it, which may trigger continuous abuse. 
                            </p>
                            <p>
                            Abuse of heroin in pregnancy, apart from the risk of spontaneous abortion, is associated with a lower weight of the newborn, which is an important risk factor for later childhood development problems. If the mother often abuses drugs, the child may be born physically dependent on heroin and may suffer from neonatal abstinence syndrome which requires hospitalization.
                            </p>
                        </div>
                    </ScrollArea>
                </div>
            ),
            Cocaine: 
            (
                <div className='text'>
                    <h2>COCAINE</h2>
                    <ScrollArea speed={0.8} smoothScrolling>
                        <div className='column'>
                            <h3>WHAT IS COCAINE?</h3>
                            <p>
                            Cocaine is a stimulant drug that very easily causes addiction. It is produced from the leaves of a coca plant originating in South America. It causes a feeling of short-term euphoria, a spike of energy and talkativeness with additional potentially dangerous physical effects such as the increased heart rate and high blood pressure.
                            </p>
                            <p>
                            Cocaine comes in form of powder. Crack is a type of cocaine processed in such way to obtain rock crystal (“freebase cocaine”). Streetnames •	Blow, Coke, Crack, Rock, Snow 
                            </p>
                            <h3>EFFECTS</h3>
                            <p>
                            Cocaine is a strong central nervous system stimulant that boosts the formation of neurotransmitters of dopamine, which is responsible for mood regulation.
                            </p>
                            <p>
                            Long-term use of cocaine can cause long-term changes in the brain, which can lead to addiction. Long-term consumption often leads to cocaine tolerance. Many users of cocaine do not manage to achieve the rush they experienced during their first consumption. Some users increase their doses trying to increase and prolong the effects of cocaine, which can increase the risk of additional psychological and physiological effects.
                            </p>
                        </div>
                        <div className='column'>
                            <h3>RISKS AND HEALTH HAZARDS</h3>
                            <p>
                            Cocaine narrows blood vessels, enlarges pupils and increases body temperature, speeds up heart rate, and increases blood pressure. It can cause headache and gastrointestinal complications such as pain in the stomach and nausea. Cocaine causes a decreased appetite, which is why chronic consumers are often undernourished. Cocaine users can experience a heart attack or a stroke that can have fatal consequences. Deaths caused by the use of cocaine are often the result of heart or respiratory failure.
                            </p>
                            <p>
                            Cocaine sniffing can result in hearing loss, nasal bleeding, problems with swallowing, hoarse voice, and a chronic problem with a runny nose. Swallowing cocaine can lead to severe intestinal gangrene. Cocaine injected with a syringe can lead to severe allergic reactions. Consecutive consumption of cocaine can lead to irritability and anxiety. Cocaine users can also experience serious paranoia, a loss of touch with reality and auditory hallucinations.
                            </p>
                            <p>
                            Cocaine is even more dangerous if it is combined with other drugs or alcohol. The combination of cocaine and heroin (known as "speedball") has a particularly high risk of fatal overdose.
                            </p>
                        </div>
                    </ScrollArea>
                </div>
            ),
            Steroids: 
            (
                <div className='text'>
                    <h2>SYNTHETIC CATHINONES</h2>
                    <ScrollArea speed={0.8} smoothScrolling>
                        <div className='column'>
                            <h3>WHAT ARE SYNTHETIC CATHINONES?</h3>
                            <p>
                            Synthetic cathinones are connected to cathinone, one of the psychoactive components in khat. Cathinone derivatives are β-keto (βk) analogues of the corresponding phenethylamine. These products are usually found as a very clean white or brown powder, less often as pills.
                            </p>
                            <p>
                            They are often sold with false instructions: “plant food”, “bathing salts” or “research chemicals” with printed instructions that they are not intended for human use.
                            </p>
                            <p>
                            Street names are M-Cat, meph, drone, miaow, meow meow, subcoca-1 ,  bubbles
                            </p>
                            <h3>EFFECTS</h3>
                            <p>
                            Cathinone derivatives have effects similar to cocaine and MDMA amphetamine (ecstasy), but little is known about their detailed pharmacology. 
                            </p>
                            <p>
                            When the effects subside, users may feel irritable, anxious, restless, depressive and apathetic.
                            </p>
                        </div>
                        <div className='column'>
                            <h3>RISKS AND HEALTH HAZARDS</h3>
                            <p>
                            Cathinones act as stimulants for the central nervous system (CNS). They can cause an increased heartbeat, heightened blood pressure, kidney and liver problems.
                            </p>
                            <p>
                            The first toxicologically confirmed case of death directly connected to the use of mephedrone was recorded in Sweden in 2008.
                            </p>
                        </div>
                    </ScrollArea>
                </div>
            ),
            NewPsychoactiveSubstances: 
            (
                <div className='text'>
                    <h2>Synthetic cannabinoids</h2>
                    <ScrollArea speed={0.8} smoothScrolling>
                        <div className='column'>
                            <h3>WHAT ARE SYNTHETIC CANNABINOIDS?</h3>
                            <p>
                            Spice refers to a wide spectrum of plant mixtures that cause experiences similar to those when consuming marijuana (cannabis) and are sold as “safe”, legal alternatives of that drug. They are sold under many names, including K2, fake weed, Yucatan Fire, Skunk, Moon Rocks, Galaxy, Rainbow, while claiming they are “not for human use”. These products contain dry, chopped up plants and chemical additives responsible for the psychoactive effects (that change brain functions).
                            </p>
                            <p>
                            Labels of Spice products often claim that it contains “natural” psychoactive materials extracted from various plants. Spice product does indeed contain dry plants, but the chemical analysis shows that product ingredients are actually synthetic cannabinoids.
                            </p>
                            <h3>EFFECTS</h3>
                            <p>
                            In some cases, Spice effects are much stronger than marijuana. Some consumers have talked about effects such as anxiety, paranoia and hallucinations. Synthetic cannabinoids affect the same receptors as THC (the main psychoactive ingredient of marijuana). Their effect is much stronger than THC, which can lead to much stronger and less predictable consequences. Due to the fact that the chemical composition of many products sold as Spice is unknown, it is possible that some types contain substances that can have much more dangerous consequences than expected.
                            </p>
                        </div>
                        <div className='column'>
                            <h3>RISKS AND HEALTH HAZARDS</h3>
                            <p>
                            Synthetic cannabinoids cause an increased heart rate, vomiting, nervousness, confusion and hallucinations. Spice can also increase blood pressure and cause a reduced flow of blood to the heart. In several cases it was connected to heart attacks.
                            </p>
                            <p>
                            There is a specific danger in not knowing what chemical additives these products are treated with, nor in what quantities.
                            </p>
                        </div>
                    </ScrollArea>
                </div>
            ),
            Hallucinogens: 
            (
                <div className='text'>
                    <h2>HALLUCINOGENS</h2>
                    <ScrollArea speed={0.8} smoothScrolling>
                        <div className='column'>
                            <h3>LSD, PEYOTE, PSILOCYBIN AND PCP</h3>
                            <p>
                            Four most common types of hallucinogens:
                            </p>
                            <ul>
                                <li>
                                LSD (d-lysergic acid diethylamide) is one of the best-known chemicals that affect the changes in mood. It is made from lysergic acid that can be found in ergot, a mushroom that grows on rye and other grains.
                                </li>
                                <li>
                                Peyote is a small cactus which contains the main active ingredient, mescaline. This plant was used by the natives in north Mexico and southwest of the USA as part of religious ceremonies.
                                </li>
                                <li>
                                Psilocybin (4-phosphoryloxy-N,N-dimethyltryptamine) can be gained from several types of mushrooms typical for tropical and subtropical parts of South America, Mexico and USA. 
                                </li>
                                <li>
                                PCP (phencyclidine) was developed in the 1950s as an intravenous anesthetic. Its anesthetic use was cancelled due to serious effects it causes.
                                </li>
                            </ul>
                            <p>
                            It is due to their unpredictable nature that the use of hallucinogens can be very dangerous.
                            </p>
                            <ul>
                                <li>
                                LSD is sold in pills, capsules and occasionally as a liquid. LSD is often added to absorbing paper, after which it is split into decorative papers, each piece equal to one dose. Experiences of use, which are often described as “trips” are long and finish after about 12 hours.
                                </li>
                                <li>
                                Peyote: parts of the peyote cactus points, often called the crown, are cut from the root and dried. After that they are chewed or dipped in water to create the psychoactive liquid. 
                                </li>
                                <li>
                                Psilocybin: Mushroom with psilocybin are available fresh or dried.
                                </li>
                                <li>
                                PCP is a white, crystal powder, easily soluble in water or alcohol. It has a specific bitter taste. It is most commonly mixed with paint and sold at the illegal drug market in different shapes, such as pills, capsules or colored powder.
                                </li>
                            </ul>
                            
                        </div>
                        <div className='column'>
                            <h3>EFFECTS</h3>
                            <p>
                            LSD, peyote, psilocybin and PCP are drugs that cause hallucinations that significantly distort human perception of reality. Under the influence of hallucinations, people see images, hear sounds and feel stimuli that seem real, although they are not. Some hallucinogens lead to sudden and strong mood changes.
                            </p>
                            <p>
                            Hallucinogens lead to an interaction disorder between the nerve cells and the neurotransmitter, serotonin. Serotonin is found in the brain and spine and is included in the control of behavior, perception and regulation of mood, hunger, temperature, sexual behavior, muscle control and sense perception.
                            </p>
                            <p>
                            Under the influence of hallucinogens, consumers have the feeling that they can hear color and see sound. These changes can be terrifying and can cause panic. Some lose control or are afraid that they are losing their mind or they are going to die. LSD users can experience “flashbacks” that happen without warning, and can appear days, months or years after using LSD. These “flashback” can lead to a significantly reduced social or professional function.
                            </p>
                            <h3>RISKS AND HEALTH HAZARDS</h3>
                            <p>
                            Using hallucinogens causes serious mental and physical health damages. Hallucinogens cause dilated pupils, increase in body temperature and heart rate, as well as blood pressure. Other effects include increased sweating, loss of appetite, throwing up, nausea, insomnia, mouth dryness and convulsions.
                            </p>
                            <p>
                            In higher doses blood pressure, pulse and respiratory rate can drop. This can be followed by nausea, vomiting, blurry vision, eye-roll, slobbering, loss of balance and vertigo. Hallucinogen users are often brought to ER for overdose and other serious adverse effects. While intoxicated, they can become violent or suicidal and can, therefore, present danger to themselves and others. Large doses can cause attacks, coma or death (although death is usually caused by accidental self-hurt or suicide while intoxicated).
                            </p>
                        </div>
                    </ScrollArea>
                </div>
            ),
            Fentanil: 
            (
                <div className='text'>
                    <h2>FENTANYL</h2>
                    <ScrollArea speed={0.8} smoothScrolling>
                        <div className='column'>
                            <h3>WHAT IS FENTANYL?</h3>
                            <p>
                            Fentanyl is a narcotic analgesic with the potency of at least 80 times that of morphine. Fentanyl and its derivatives (alfentanil, sufentanil, remifentanil, and carfentanil) are used as anesthetics and analgesic and human and veterinary medicine (carfentanil). They are under international control, just like a number of highly potent non-pharmaceutical fentanyl derivatives (NPE), such as 3-metilfentanil which is synthesized illegally and sold as “synthetic heroin”. 
                            </p>
                            <p>
                            Fentanyl and its salts are in the shape of white, granulated or crystal powder. Illegal forms include the light-yellow powder called “White Persian”, containing 3-metilfentanil, and sometimes “paper trips” (think pieces of cardboard impregnated with fentanyl).
                            </p>
                            <p>
                            China White, Synthetic Heroin, Drop Dead, Flatline, Lethal Injection, Apache, China Girl, Chinatown, Dance Fever, Great Bear, Poison, Tango & Cash, TNT.  Perc-o-Pops and Lollipops are street names for fentanyl.
                            </p>
                            
                        </div>
                        <div className='column'>
                            <h3>EFFECTS</h3>
                            <p>
                            Apart from analgesic effects (reduction of pain), fentanyls as a group cause drowsiness and euphoria, with the latter less pronounced than with heroin and morphine. Most common side-effects include nausea, vertigo, vomiting, tiredness, headache, constipation, anemia and peripheral edema. 
                            </p>
                            <p>
                            Tolerance and addiction are quickly developed after several uses. Characteristic withdrawal symptoms of fentanyl (sweating, anxiety, diarrhea, pain in the bones, stomach cramps, convulsions or “goose flash”) appear when the user stops taking it. 
                            </p>
                            <h3>RISKS AND HEALTH HAZARDS</h3>
                            <p>
                            Serious interactions can appear when fentanyls are mixed with heroin, cocaine, alcohol and other CNS depressants, such as benzodiazepines. 	
                            </p>
                            <p>
                            Overdose leads to respiratory depression. Sudden death can appear due to heart arrest or hard anaphylactic reaction.
                            </p>
                        </div>
                    </ScrollArea>
                </div>
            ),
    },

    rs: {
        negativeEffectsOfDrugs: 
            (
                <div className='text'>
                    <h2>Negativni uticaji droge</h2>
                        <ScrollArea speed={0.8} smoothScrolling>
                        <div className='column'>
                            <h3>HEMIJA MOZGA</h3>
                            <p>
                                Mozak je najkompleksniji organ u tvom telu i reguliše životne procese celog
                                organizma koji se odražavaju na tebe i fizički i mentalno. Baš zato te upravo mozak
                                u velikoj meri čini onim što jesi, sa svim tvojim osećanjima, ambicijama, željama i
                                sećanjima. Droge i alkohol oblikuju način na koji se osećaš, direkno menjajući
                                hemijske procese koji utiču na nesmetano funkcionisanje mozga i time transformišu
                                tebe kao osobu, psihički i fizički.
                            </p>
                            <p>
                                Kako se ovo dogodi? Naš mozak prirodno oslobađa supstancu dopamin koja je
                                povezana sa euforijom, motivacijom i nebrojenim drugim pozitivnim efektima. Pod
                                uticajem droga koje navode na veliko oslobađanje dopamina, dolazi do euforije i
                                želje za ponavljanjem tog osećaja.
                            </p>
                            <p>
                                Priroda je već namestila da imamo redovne „pošiljke“ dopamina, ali šta se desi kada
                                istreniramo svoj mozak da radi „prekovremeno“ i da dostavlja velike količine ove
                                supstance? Ponavljanjem unosa, mozak postaje naviknut na naglo oslobađanje
                                dopamina i zahteva ga još, povlačeći sa sobom niz opasnih efekata na telo i um.
                                Drugim rečima, funkcionisanje tela i svesti se menja bez naše volje i uticaja.
                            </p>
                            <h3>ZDRAVSTVENE КOMPLIКACIJE</h3>
                            <p>
                                Droga i alkohol utiču na delove tela. Samo neke od posledica jesu opšta slabost,
                                grčevi mišića, poremećaj rada srca, poremećaj krvotoka, oštećenje bubrega i jetre,
                                kao i smrtni ishod.
                            </p>
                            <p>
                                U 2017. godini je u Srbiji registrovano 512 novih zavisnika od opijata koji su započeli
                                lečenje. Prosečan uzrast kada se počinje sa eksperimentisanjem sa drogama
                                (najčešće marihuanom) jeste 16 godina, a prosečan uzrast kada se počinje sa
                                korišćenjem heroina jeste 21 godina. Epidemiološki podaci ukazuju da je prosečan
                                uzrast heroinskih zavisnika koji su na lečenju 33,2 godine. Dakle, od perioda kada se
                                počne sa upotrebom heroina do momenta kada se zatraži lečenje prođe i više od 10
                                godina! Upravo je to period tokom koga zavisnici imaju rizično ponašanje koje nosi
                                posledice u vidu velike opasnosti od infektivnih bolesti povezanih sa upotrebom
                                droga, ali i od mentalnih poremećaja koji mogu da ostave trajne efekte.
                            </p>
                            <p>
                                Upotreba kanabisa među mladima je povezana sa neželjenim ishodima kao što su
                                narušena kratkoročna memorija i pažnja, povećan rizik od neuropsihijatrijskih
                                poremećaja, veća verovatnoća za uzimanje drugih ilegalnih droga i zavisnost od
                                kanabisa. Istraživanje upotrebe kanabisa kod učenika prvih razreda srednjih škola,
                                sproveo je Institut za javno zdravlje Srbije u 2018. godini. Кanabis je tokom života
                                bar jednom probalo njih 10,6%, Među učenicima koji su ikada probali kanabis i
                                odgovorili na pitanje o uzrastu kada su prvi put to učinili, najveći broj (45,9%) je prvi
                                put probao kanabis u 15. godini života. Mladi ovog uzrasta često ne znaju I ne
                                razmišljaju o rizicima, zbog čega često ulaze u veći problem sa drogom.
                            </p>
                            <h3>INFEKTIVNE BOLESTI</h3>
                            <p>
                                Uticaj droga i alkohola na telo ima posledice na mnogobrojne delove života.
                                Ispoljava se i na svakodnevno ponašanje, loše odnose u porodici i među vršnjacima,
                                dovodi do rizičnog ponašanja upotrebom zajedničkih igala i špriceva, što dovodi do
                                povećanog rizika od zaražavanja hepatitisom B i C, te virusom HIV-a. U 2017. godini
                                u EU je zabeleženo 1.046 novih slučajeva dijagnoze HIV-a.
                            </p>
                            <p>
                                U Srbiji, prema podacima o infektivnim bolestima povezanim sa upotrebom droge,
                                najveći procenat intravenskih korisnika je zaražen hepatitisom C (44.8%), hepatitis B
                                ima 4.6% , a HIV 1.4%.
                            </p>
                            <p>
                                Opasnost od infekcije ne leži samo u HIV virusu i hepatitisu, već i u kontaminaciji
                                droge nekim infektivnim agensima. U svetu su evidentirani slučajevi infekcije
                                salmonelom, botulinskim egzotoksinom, pa čak i antraksom, zbog kontaminacije.
                                Ovo su veoma ozbiljna infektivna oboljenja, koja mogu da izazovu smrt.
                            </p>
                        </div>
                        <div className='column'>
                            <h3>POVREDE I SMRTNOST</h3>
                            <p>
                                Upotrebom droga i alkohola ugrožavamo i sebe i druge, jer povećamo rizik od
                                fizičkih povreda i saobraćajnih nezgoda.
                            </p>
                            <p>
                                Veoma zabrinjavajući podatak je da je u 2017. godini u Srbiji evidentirano 89
                                smrtnih slučajeva povezanih sa upotrebom droge. Glavni uzrok smrti bilo je
                                predoziranje opijatima, ali i tri slučaja samoubistva, uzrasta 18, 22 i 29 godina, kod
                                kojih je u krvi identifikovano prisustvo THC (psihoaktivne komponente kanabisa).
                            </p>
                            <p>
                                Nove psihoaktivne supstance, uključujući sintetičke opioide, sintetičke kanabinoide i
                                sintetičke katinone, povezuju se sa smrtnim slučajevima i slučajevima akutnih
                                trovanja u Evropi. Posebno su zabrinjavajući derivati fentanila koji čine većinu novih
                                opioida. Te supstance su izuzetno snažne i vrlo male količine mogu uzrokovati
                                trovanja opasna po život zbog brze i teške respiratorne depresije. Tokom 2018.
                                godine svake nedelje prijavljena je po jedna nova supstanca, pri čemu se pojavljuje
                                veći broj sintetičkih opioda i benzodiazepina.
                            </p>
                            <h3>PRAVNE POSLEDICE</h3>
                            <p>
                                Upotrebom droge i alkohola stavljamo sebe u poziciju u kojoj se suočavamo sa
                                pravnim posledicama ovakvog ponašanja. Usled smanjene pažnje i promenjenih
                                prioriteta u izboru aktivnosti, mogućnosti zaposlenja, nedostatku obezbeđivanja
                                finansijskih sredstava za život, stigme na radnom mestu i nepoverenja u kvalitet
                                našeg rada, i mi sami i drugi nas isključuju iz društva i stvaraju se loši uslovi za nas i
                                našu porodicu.
                            </p>
                            <p>
                                Krivična dela neraskidivo su vezana uz upotrebu droge. Mnogi zavisnici imaju
                                problem sa zakonom, a podaci pokazuju da čak 30.3% ima izrečenu meru zatvora
                                zbog krivičnih dela povezanih sa drogom.
                            </p>
                        </div>
                    </ScrollArea>
                </div>
            ),
            Amphetamines: 
            (
                <div className='text'>
                    <h2>AMFETAMINI</h2>
                        <ScrollArea speed={0.8} smoothScrolling>
                        <div className='column'>
                            <h3>ŠTA SU AMFETAMINI?</h3>
                            <p>
                                Amfetamini su sintetičke droge koje se najčešće pojavljuju u obliku belog
                                praha koji deluje kao stimulans centralnog nervnog sistema. Najčešći oblik
                                amfetamina je beli ili bezbojan prah rastvorljiv u vodi. Tablete koje sadrže amfetamin
                                mogu nositi slične logotipove kao što su na MDMA i drugim ecstasy tabletama.
                                Ulični nazivi su „speed“, „base“ i „whizz“.
                            </p>
                            <h3>DEJSTVO</h3>
                            <p>
                                Amfetamin kao stimulans centralnog nervnog sistema izaziva osećaj
                                povećanog samopouzdanja, društvenosti i energije, nakon čega sledi povišen krvni
                                pritisak i tahikardija. Potiskuje apetit i umor i dovodi do nesanice. Oralnom primenom,
                                dejstvo obično počinje u roku od 30 minuta i može trajati satima. Kasnije se korisnici
                                mogu osećati razdražljivo, nemirno, uznemireno, depresivno i bezvoljno.
                            </p>
                        </div>
                        <div className='column'>
                            <h3>RIZICI I OPASNOSTI PO ZDRAVLJE</h3>
                            <p>
                                Akutna intoksikacija uzrokuje ozbiljne kardiovaskularne poremećaje, kao i
                                probleme u ponašanju koji uključuju uznemirenost, zbunjenost, paranoju,
                                impulsivnost i nasilje. Hronična primena amfetamina uzrokuje neurohemijske i
                                neuroanatomske promene i ima za posledicu oštećenje centralnog nervnog sistema,
                                što rezultira manjkom memorije i smanjenjem sposobnosti donošenja odluka kao i
                                verbalnog zaključivanja. Neki simptomi liče na paranoidnu shizofreniju.
                            </p>
                            <p>
                                Veoma zabrinjavajući podatak je da je u 2017. godini u Srbiji evidentirano 89
                                smrtnih slučajeva povezanih sa upotrebom droge. Glavni uzrok smrti bilo je
                                predoziranje opijatima, ali i tri slučaja samoubistva, uzrasta 18, 22 i 29 godina, kod
                                kojih je u krvi identifikovano prisustvo THC (psihoaktivne komponente kanabisa).
                            </p>
                            <p>
                                Intarvenskim ubrizgavanjem amfetamina može doći do virusnih infekcija (HIV i
                                hepatitisa) kao što je to slučaj kod drugih droga koje se ubrizgavaju, poput heroina.

                                U nekim zemljama evidentirani su i smrtni slučajevi povezani sa upotrebom
                                amfetamina.
                            </p>
                        </div>
                    </ScrollArea>
                </div>
            ),
            Marijuana: 
            (
                <div className='text'>
                    <h2>MARIHUANA / KANABIS</h2>
                    <ScrollArea speed={0.8} smoothScrolling>
                        <div className='column'>
                            <h3>ŠTA JE MARIHUANA?</h3>
                            <p>
                                Kanabis je prirodni proizvod, čiji glavni psihoaktivni sastojak je
                                tetrahidrokanabinol (Δ9-THC). Biljka kanabisa (Cannabis sativa L.) je široko
                                rasprostranjena i raste u umerenim i tropskim područjima. Biljni kanabis sastoji se od
                                sušenih cvetnih vrhova i lišća. Kanabis smola je supstanca izrađena od smolastih
                                delova biljke, a kanabisovo ulje (hašiš) je ekstrakt rastvora kanabisa.
                                Kanabis proizvodi i Δ9-THC su pod međunarodnom kontrolom.
                                Marihuana se najčešće puši u obliku ručno rolanih cigareta (joint) ili se puši u
                                lulama &quot;pipes&quot; ili &quot;water pipes&quot; (bongovima). Dim marihuane ima prepoznatljiv slatko-
                                kiseli miris.
                            </p>
                            <h3>DEJSTVO</h3>
                            <p>
                                Kada se marihuana puši, THC ubrzano prolazi iz pluća u krvotok. Bez obzira
                                na način konzumiranja, THC utiče na specifične receptore u moždanim ćelijama koji
                                se nazivaju kababinoidni receptori i oni su deo endokanabinoidnog sistema u mozgu.
                                Najveća gustina receptora kanabinoida je pronađena u delovima mozga koji utiču na
                                osećaj zadovoljstva, pamćenje, razmišljanje, koncentraciju, percepciju dodira i
                                vremena i koordinaciju pokreta. Marihuana pojačava aktivnost endokanabinoidnoog
                                sistema, uzrokujući osećaj uzbuđenja (high) i ostale efekte koje konzumenti osećaju.
                                Ti efekti uključuju izmenjenu percepciju i raspoloženje, poremećenu koordinaciju,
                                probleme s razmišljanjem, rešavanjem problema i poremećenu sposobnost učenja i
                                pamćenja.
                            </p>
                            <p>
                                Marihuana takođe utiče na razvoj mozga, i kada se konzumira češće od strane
                                mladih posledice na razmišljanje i memoriju mogu trajati duže ili čak mogu biti trajne.
                                Nedavno istraživanje na konzumentima marihuane koji su s konzumiranjem započeli

                                u pubertetu otkrilo je znatno smanjenu povezanost u područjima mozga odgovornim
                                za učenje i pamćenje. Istraživanje na Novom Zelandu pokazalo je da su mladi, koji
                                su konzumirali marihuanu u većim količinama za vreme puberteta, izgubili u proseku
                                8 bodova IQ-a.
                            </p>
                        </div>
                        <div className='column'>
                            <h3>RIZICI I OPASNOSTI PO ZDRAVLJE</h3>
                            <p>
                                Marihuana može izazvati širok spektar posledica na fizičko i mentalno
                                zdravlje. Dim marihuane nadražuje pluća zbog čega pušači marihuane mogu imati
                                respiratorne probleme kao što su kašalj i pojačani rizik od upale pluća. Još uvek nije
                                poznato da li pušenje marihuane doprinosi riziku od dobijanja raka pluća. Marihuana
                                takođe povećava broj otkucaja srca ubrzo nakon pušenja za 20-100 posto, a taj
                                efekat može trajati i do tri sata. Konzumenti marihuane imaju 4,8 puta veći rizik od
                                srčanog udara tokom prvog sata nakon pušenja.
                            </p>
                            <p>
                                Mnoga istraživanja su povezala dugotrajno konzumiranje marihuane s
                                mentalnim bolestima. Velika doza marihuane može izazvati privremeno psihotičnu
                                reakciju kod nekih konzumenata (uključujući halucinacije i paranoju). Konzumiranje
                                marihuane može pogoršati tok bolesti kod pacijenata sa shizofrenijom. Veliki broj
                                istraživanja koja prate konzumente tokom određenog perioda vremena ukazala su na
                                povezanost konzumiranja marihuane i kasnijeg razvoja psihoze. Ova povezanost
                                zavisi od količine koju osoba konzumira, jačine droge i uzrasta prvog konzumiranja –
                                oni koji su počeli s konzumiranjem u mlađoj dobi su u većem riziku za kasnije
                                psihičke probleme.
                            </p>
                            <p>
                                Povezanost je takođe otkrivena između upotrebe marihuane i ostalih
                                mentalnih problema kako što su depresija, anksioznost, razmišljanje o samoubistvu i
                                poremećaji ličnosti uključujući nedostatak motivacije.
                            </p>
                            <p>
                                Konzumiranje marihuane tokom trudnoće povezano je sa povećanim rizikom
                                kod beba. Dete može imati različite probleme uključujući probleme s zadržavanjem
                                pažnje, pamćenjem i sposobnošću rešavanja problema u kasnijem uzrastu.
                                Dodatno, marihuana ima ozbiljne posledice na sposobnost procene i motornu
                                koordinaciju i doprinosi povećanom riziku od povreda ili smrtnih posledica za vreme
                                vožnje automobila.
                            </p>
                        </div>
                    </ScrollArea>
                </div>
            ),
            Ecstasy: 
            (
                <div className='text'>
                    <h2>ECSTASY (MDMA)</h2>
                    <ScrollArea speed={0.8} smoothScrolling>
                        <div className='column'>
                            <h3>ŠTA JE ECSTASY?</h3>
                            <p>
                                MDMA (3,4-metilenedioksi-metamfetamin) popularno nazvan „ecstasy“ ili (od
                                nedavno i Molly) je sintetička psihoaktivna droga koja ima sličnosti sa stimulansom
                                amfetaminom, ali i s halucinogenom meskalinom. Obično se nalazi u vidu kapsula,
                                tableta ili praha. Najčešće se konzumira oralno ili ušmrkavanjem. Popularni naziv
                                Molly (sleng za „molekularno“) odnosi se na čist kristalni prah u obliku MDMA-a koji
                                se najčešće prodaje u kapsulama.
                                Ulični nazivi su „ecstasy“, „bombon“, „bonkas“, eks…
                            </p>
                            <h3>DEJSTVO</h3>
                            <p>
                                MDMA deluje na povećanje aktivnosti tri neurotransmitera – serotonina,
                                dopamina i norepinefrina. Emotivni i socijalni efekti delovanja MDMA su direktno ili
                                indirektno uzrokovani oslobađanjem velike količine serotonina koji utiče na
                                raspoloženje (ali i na druge funkcije poput apetita i spavanja). Serotonin takođe
                                izaziva oslobođenje hormona oksitocina i vazopresina. koji igraju važnu ulogu za
                                osećanje ljubavi, poverenja, seksualnog uzbuđenja.
                            </p>
                        </div>
                        <div className='column'>
                            <h3>RIZICI I OPASNOSTI PO ZDRAVLJE</h3>
                            <p>
                                Poremećaj nivoa serotonina prouzrokovan konzumiranjem MDMA dovodi do
                                negativnih efekata, kao što su zbunjenost, depresija, problemi sa spavanjem, žudnju
                                za drogom i anksioznost, do kojih može doći ubrzo nakon prestanka konzumiranja
                                droge ili kroz nekoliko dana pa čak nekoliko nedelja kasnije.Neke osobe koje su duže
                                vreme konzumirale MDMA iskusile su dugotrajnu zbunjenost, depresiju, probleme sa
                                spavanjem i probleme s pažnjom i pamćenjem.
                            </p>
                            <p>
                                MDMA ima efekte kao i ostali stimulansi kao što su kokain i amfetamini. Ti
                                efekti su povećan broj otkucaja srca i povišen krvni pritisak, koji su posebno rizični za
                                ljude s vaskularnim problemima ili srčanim bolestima. Konzumenti MDMA mogu
                                iskusiti i ostale simptome kao što su napetost mišića, nekontrolisano škrgutanje
                                zubima, mučnina, zamagljeni vid, nesvestica, drhtavica i znojenje.
                            </p>
                            <p>
                                U velikim dozama, MDMA može uticati na termoregulaciju, što može dovesti
                                do naglog povećanja temperature (hipertermija) što ima za posledicu zastoj jetre,
                                bubrega, kardiovaskularnog sistema ili čak smrt.
                            </p>
                            <p>
                                Rizik predstavlja činjenica da tablete, pa i kapsule navodno čistog ekstazija
                                „Molly“, ponekad sadrže druge vrste droga ili dodatke. To može uključivati efedrin
                                (stimulans), ketamin, kofein, kokain, metamfetamin ili čak od nedavno sintetički
                                katinon (psihoaktivni sastojak u „solima za kupanje“). Konzumenti koji kombinuju
                                takvu mešavinu s dodatnim supstancama kao što su marihuana ili alkohol mogu
                                dovesti do još većeg rizika od štetnih posledica po zdravlje.
                            </p>
                            <p>
                                Negativni efekti konzumacije MDMA su iscrpljenost, usporena koordinacija
                                pokreta, dekoncentracija, glavobolja, poremećaj spavanja, mučnina praćena
                                povraćanjem, mamurluk koji može potrajati danima i koji je obično praćen jakom
                                depresijom, kao i erektilne poteškoće. Dugotrajne posledice kod učestalih
                                konzumenata su ozbiljna oštećenja jetre i bubrega. Dejstvo MDMA na pojačan
                                seksualni nagon (osobito u kombinaciji sa sildenafilom) može podstaknuti nezaštićeni
                                seksualni odnos koji je rizični faktor polne bolesti, HIV i hepatitis.
                            </p>
                        </div>
                    </ScrollArea>
                </div>
            ),
            Heroin: 
            (
                <div className='text'>
                    <h2>HEROIN</h2>
                    <ScrollArea speed={0.8} smoothScrolling>
                        <div className='column'>
                            <h3>ŠTA JE HEROIN?</h3>
                            <p>
                                Heroin je opijatska droga koja je sintetisana od supstance koja se prirodno
                                pojavljuje i izvlači iz semena azijske opijatske biljke maka. Heroin se najčešće
                                pojavljuje u obliku belog i smeđeg praha ili kao crna lepljiva supstanca koja je
                                poznata pod nazivom „crni katran heroin“.
                                Heroin se najčešće koristi ubrizgavanjem, inhaliranjem, ušmrkavanjem ili se
                                može pušiti. Izaziva zavisnost u vidu hronične recidivirajuće bolesti prouzrokovane
                                promenama u mozgu.
                            </p>
                            <h3>DEJSTVO</h3>
                            <p>
                                U mozgu se heroin vezuje za opioidne receptore koji su smešteni u delovima
                                mozga koji su uključeni u percepciju bola. Receptori opioida su smešteni u
                                moždanom stablu koji kontroliše automatske procese koji su neophodni za život kao
                                što je krvni pritisak i disanje. Predoziranje heroinom često uključuje prestanak disanja
                                što može biti smrtonosno.
                            </p>
                            <p>
                                Nakon intravenskog ubrizgavanja heroina javlja se osećaj euforije koju prate:
                                suvoća usta, isparavanje toplote sa površine kože, osećaj težine u ekstremitetima i
                                oslabljeno mentalno funkcionisanje.
                            </p>
                            <p>
                                Nakon početne euforije konzument dolazi u stanje dremanja, stanja u kojem
                                se izmenjuju stanje pospanosti i stanje budnosti. Redovno konzumiranje heroina
                                dovodi do promena u funkcionisanju mozga. Jedna od posledica je tolerancija prema
                                kojoj je sve više droge potrebno kako bi se postigao jednaki intenzitet dejstva droge.
                                Druga posledica je zavisnost koju karakteriše potreba za neprestanim
                                konzumiranjem droge kako bi se izbegli simptomi prekida uzimanja droge.
                            </p>
                        </div>
                        <div className='column'>
                            <h3>RIZICI I OPASNOSTI PO ZDRAVLJE</h3>
                            <p>
                                Ubrizgavanje droge povećava rizik od zaraze HIV-om ili hepatitisom C (HCV),
                                imajući u vidu da su ovo krvno prenosive bolesti , što se se može dogoditi kada se
                                koristi zajednički pribor za ubrizgavanje droge (igle i špricevi). HIV-om (i nešto ređe
                                HCV-om) se takođe može zaraziti i tokom nezaštićenog seksualnog odnosa što se
                                kod konzumenata droga događa mnogo češće.
                            </p>
                            <p>
                                Zloupotreba heroina povezana je sa brojnim ozbiljnim zdravstvenim
                                posledicama, uključujući predoziranje, spontani pobačaj i zarazne bolesti poput
                                hepatitisa i HIV-a. Hronični konzumenti mogu imati pucanje vena, infekciju srčanih
                                omotača i zalistaka, gnojni čir, začepljenost i gastrointestinalne grčeve, kao i bolesti
                                jetre i bubrega. Komplikacije s plućima, uključujući različite tipove upale pluća, mogu
                                dovesti do slabijeg zdravlja konzumenata ali i do fatalnih posledica na disanje i rad
                                srca.
                            </p>
                            <p>
                                Osim posledica koje droga ima sama po sebi, ulični heroin često sadrži
                                toksična jedinjenja i aditive koji mogu začepiti vene što može dovesti do trajnog
                                oštećenja vitalnih organa poput pluća, jetre, bubrega ili mozga.
                            </p>
                            <p>
                                Hronična upotreba heroina dovodi do fizičke zavisnosti, stanja u kojem je telo
                                već naviknuto na prisustvo droge. Ukoliko konzument droge naglo smanji ili obustavi
                                upotrebu droge može iskusiti različite simptome. Ti simptomi, koji mogu početi već
                                nakon nekoliko sati nakon poslednjeg konzumiranja mogu uključivati osećaj nemira,
                                bol u mišićima i kostima, insomniju, proliv i povraćanje, nagli osećaj hladnoće i
                                trzanje delova tela. Konzumenti takođe osećaju veliku žudnju za drogom u periodu
                                dok je ne konzumiraju što može prouzrokovati kontinuiranu zloupotrebu.
                            </p>
                            <p>
                                Zloupotreba heroina u trudnoći osim rizika od spontanog pobačaja povezana
                                je s manjom težinom novorođenčeta što je važan rizični faktor za kasnije probleme u
                                razvoju deteta. Ukoliko majka često zloupotrebljava drogu, dete se može roditi fizički
                                zavisno od heroina i može patiti od neonatalnog apstinencijalnog sindroma koji
                                zahteva hospitalizaciju.
                            </p>
                        </div>
                    </ScrollArea>
                </div>
            ),
            Cocaine: 
            (
                <div className='text'>
                    <h2>KOKAIN</h2>
                    <ScrollArea speed={0.8} smoothScrolling>
                        <div className='column'>
                            <h3>ŠTA JE KOKAIN?</h3>
                            <p>
                                Kokain je stimulativna droga koja vrlo lako izaziva zavisnost. Proizvodi se od
                                lišća biljke koke poreklom iz Južne Amerike. Izaziva osećaj kratkotrajne euforije,
                                energije i pričljivosti uz dodatne potencijalno opasne fizičke efekte kao što su ubrzan
                                rad srca i povišen krvni pritisak.
                            </p>
                            <p>
                                Kokain u je obliku praha. Crack je oblik kokaina koji se obrađuje kako bi se
                                dobio rock crystal (“freebase cocaine”)
                                Ulični nazivi su • Blow, Coke, Crack, Rock, Snow
                            </p>
                            <h3>DEJSTVO</h3>
                            <p>
                                Kokain je jak stimulans centralnog nervnog sistema koji pojačava stvaranje
                                neurotransmitera dopamina, koji je odgovoran za regulisanje raspoloženja.
                            </p>
                            <p>
                                Dugotrajnom upotrebom kokain može uzrokovati dugoročne promene u
                                mozgu što može dovesti do zavisnosti. Dugotrajnim konzumiranjem često se razvija i
                                tolerancija na kokain. Mnogi korisnici kokaina ne uspevaju da postignu zadovoljstvo
                                koje su iskusili prilikom prvog konzumiranja. Neki korisnici povećavaju svoje doze
                                pokušavajući da pojačaju i produže dejstvo kokaina, što može povećati rizik od
                                dodatnih psiholoških i fizioloških efekata.
                            </p>
                        </div>
                        <div className='column'>
                            <h3>RIZICI I OPASNOSTI PO ZDRAVLJE</h3>
                            <p>
                                Kokain sužava krvne sudove, proširuje zenice i povećava telesnu temperaturu,
                                ubrzava rad srca i povećava krvi pritisak. Može uzrokovati glavobolju i
                                gastrointestinalne komplikacije poput bola u stomaku i mučninu. Kokain utiče na
                                smanjenje apetita zbog čega su hronični konzumenti često pothranjeni. Konzumenti
                                kokaina mogu doživeti srčani ili moždani udar što može imati smrtne posledice. Smrti
                                prouzrokovane upotrebom kokaina su često rezultat zastoja srca i prestanka disanja.
                            </p>
                            <p>
                                Usled ušmrkavanja kokaina može doći do gubitka čula sluha, krvarenja iz
                                nosa, problema s gutanjem, promuklosti i hroničnog problema s curenjem iz nosa.
                                Kokain koji se proguta može dovesti do ozbiljne gangrene creva. Kokain koji se
                                ubrizgava pomoću šprica može dovesti do jakih alergijskih reakcija. Uzastopna
                                konzumacija kokaina može dovesti do razdražljivosti, uznemirenosti i anksioznosti.
                                Konzumenti kokaina mogu takođe doživeti ozbiljnu paranoju pri čemu se gubi dodir
                                sa stvarnošću i doživljavaju slušne halucinacije.
                            </p>
                            <p>
                                Kokain je još opasniji ukoliko se kombinuje s drugim drogama ili alkoholom.
                                Kombinacija kokaina i heroina (poznata kao „speedball“) ima posebno veliki rizik od
                                fatalnog predoziranja.
                            </p>
                        </div>
                    </ScrollArea>
                </div>
            ),
            Steroids: 
            (
                <div className='text'>
                    <h2>SINTETIČKI KATINONI</h2>
                    <ScrollArea speed={0.8} smoothScrolling>
                        <div className='column'>
                            <h3>ŠTA SU SINTETIČКI КATINONI?</h3>
                            <p>
                                Sintetički katinoni su povezani sa matičnim jedinjenjem katinonom, jednim od
                                psihoaktivnih komponenti u khatu. Derivati katinona su β-keto (βk) analozi
                                odgovarajućeg fenetilamina. Ovi proizvodi se obično sreću kao veoma čisti beli ili
                                smeđi prah, ređe u vidu tableta.
                            </p>
                            <p>
                                Često se prodaju sa lažnim uputstvom: &quot;biljna hrana&quot;, &quot;soli za kupanje&quot; ili
                                &quot;hemikalije za istraživanje&quot; sa štampanim upozorenjem da nisu za ljudsku upotrebu.
                            </p>
                            <p>
                                Ulični nazivi su M-Cat, meph, drone, miaow, meow meow, subcoca-1 ,bubbles
                            </p>
                            <h3>DEJSTVO</h3>
                            <p>
                                Derivati katinona imaju efekte slične onima kod kokaina, amfetamina MDMA
                                (ekstazija), ali se malo zna o njihovoj detaljnoj farmakologiji.
                            </p>
                            <p>
                                Kasnije se korisnici mogu osećati razdražljivo, nemirno, uznemireno,
                                depresivno i bezvoljno.
                            </p>
                        </div>
                        <div className='column'>
                            <h3>RIZICI I OPASNOSTI PO ZDRAVLJE</h3>
                            <p>
                                Katinoni se ponašaju kao stimulansi centralnog nervnog sistema (CNS). Mogu
                                da izazovu ubrzan rad srca, povišen pritisak, probleme u radu bubrega i jetre.
                            </p>
                            <p>
                                Prvi toksikološki potvrđen smrtni slučaj direktno povezan sa upotrebom
                                mefedrona zabeležen je u Švedskoj 2008. godine.
                            </p>
                        </div>
                    </ScrollArea>
                </div>
            ),
            NewPsychoactiveSubstances: 
            (
                <div className='text'>
                    <h2>Sintetički kanabinoidi</h2>
                    <ScrollArea speed={0.8} smoothScrolling>
                        <div className='column'>
                            <h3>ŠTA SU SINTETIČKI KANABINOIDI?</h3>
                            <p>
                                Spice se odnosi na širok spektar biljnih mešavina koje izazivaju iskustva slična onim pri
                                konzumiranju marihuane (kanabisa) i koji se lažno prodaju kao „sigurne“, legalne alternative te
                                droge. Prodaje se pod mnogim imenima uključujući K2, lažna trava (fake weed), Yucatan Fire,
                                Skunk, Moon Rocks, Galaxy, Rainbow, pri čemu je navedeno da „nisu za ljudsku upotrebu“. Ti
                                proizvodi sadrže sasušene iseckane biljke i hemijske aditive koji su odgovorni za psihoaktivne
                                efekte (menjaju stanje svesti).
                            </p>
                            <p>
                                Etikete na proizvodu Spice često tvrde da sadrže „prirodne“ psihoaktivne materijale koji
                                se dobijaju od različitih biljaka. Proizvod Spice zaista sadrži osušene biljke, ali hemijska analiza
                                otkriva da su njeni sastojcisintetički kanabinoidi.
                            </p>
                            <h3>DEJSTVO</h3>
                            <p>
                                U nekim slučajevima efekti Spice-a su mnogo jači od efekata marihuane. Neki
                                konzumenti su se izjasnili o psihičkim efektima poput anksioznosti, paranoje i halucinacija.
                                Sintetički kanabinoidi utiču na iste receptore kao i THC (glavni psihoaktivni sastojak marihuane).
                                Njihovo dejstvo je mnogo jače od THC, što može dovesti do mnogo jačih i nepredvidivih
                                posledica. Upravo iz razloga što je hemijski sastav mnogih proizvoda prodavanih kao Spice
                                nepoznat,moguće je da neke vrste sadrže supstance koje mogu imati izuzetno opasnije posledice
                                od očekivanih.
                            </p>
                        </div>
                        <div className='column'>
                            <h3>RIZICI I OPASNOSTI PO ZDRAVLJE</h3>
                            <p>
                                Sintetički kanabinoidi izazivaju ubrzan rad srca, povraćanje, uzrujanost, zbunjenost i
                                halucinacije. Spice može takođe povećati krvni pritisak i prouzrokovati smanjenje dotoka krvi do
                                srca. U nekoliko slučajeva povezan je sa srčanim udarima.
                            </p>
                            <p>
                                Posebnu opasnost predstavlja što nije poznato kojim hemijskim aditivima se navedeni
                                preparati prskaju, kao ni u kojim količinama.
                            </p>
                        </div>
                    </ScrollArea>
                </div>
            ),
            Hallucinogens: 
            (
                <div className='text'>
                    <h2>HALUCINOGENI</h2>
                    <ScrollArea speed={0.8} smoothScrolling>
                        <div className='column'>
                            <h3>LSD, PEJOTL, PSILOCIBIN I PCP</h3>
                            <p>
                                Četiri najčešća tipa halucinogena:
                            </p>
                            <ul>
                                <li>
                                    LSD (d-lysergic acid diethylamide) je jedna od najpoznatijih hemikalija koje utiču
                                    na promenu raspoloženja. Proizvodi se iz lizerginske kiseline koju možemo
                                    pronaći u ergotu, gljivici koja raste na raži i drugim žitaricama.
                                </li>
                                <li>
                                    Pejotl je mali kaktus u kojem je glavni aktivni sastojak meskalin. Ovu biljku su
                                    koristili domoroci u severnom Meksiku i na jugozapadu SAD kao deo religijskih
                                    ceremonija.
                                </li>
                                <li>
                                    Psilocibin (4-phosphoryloxy-N,N-dimethyltryptamine) se dobija iz nekoliko tipova
                                    gljiva koje su tipične za tropske i suptropske delove Južne Amerike, Meksika i
                                    SAD.
                                </li>
                                <li>
                                    PCP (phencyclidine) je razvijen 1950-tih kao intravenski anestetik. Njegova
                                    upotreba kao anestetika je ukinuta zbog ozbiljnih posledica koje izaziva.
                                </li>
                            </ul>
                            <p>
                                Upravo zbog njihove nepredvidive prirode upotreba halucinogena može biti veoma
                                opasna.
                            </p>
                            <ul>
                                <li>
                                    LSD se prodaje u tabletama, kapsulama i povremeno u tečnom obliku. LSD se
                                    često dodaje apsorbujućem papiru koji se nakon toga deli na dekorativne papire,
                                    pri čemu je svaki papir ekvivalentan jednoj dozi. Iskustva upotrebe, koja se često
                                    opisuju kao „putovanja“ („trips“) su duga, te završavaju nakon otprilike 12 sati.
                                </li>
                                <li>
                                    Pejotl: delovi vrhova pejotl kaktusa, koji se često naziva kruna, režu se iz korena i
                                    suše. Uglavnom se žvaću ili se namoče u vodu kako bi proizveli psihoaktivnu
                                    tekućinu.
                                </li>
                                <li>
                                    Psilocibin: Gljive koje u sebi imaju psilosibin su dostupne sveže ili osušene.
                                </li>
                                <li>
                                    PCP je u vidu belog kristalnog praha koji je lako toplv u vodi ili alkoholu. Ima
                                    specifičan gorak ukus. Najčešće se meša sa bojama i tako prodaje na ilegalnom
                                    tržištu droga u različitim oblicima poput tableta, kapsula i obojenog praha.
                                </li>
                            </ul>
                            
                        </div>
                        <div className='column'>
                            <h3>DEJSTVO</h3>
                            <p>
                                LSD, pejotl, psilocibin i PCP su droge koje uzrokuju halucinacije, koje veoma
                                iskrivljuju ljudsku percepciju stvarnosti. Pod uticajem halucinacija ljudi vide slike, čuju
                                zvukove i osećaju nadražaje koji se im čine stvarnim, a zapravo nisu. Neki
                                halucinogeni dovode do naglih jakih promena raspoloženja.
                            </p>
                            <p>
                                Halucinogeni dovode do poremećaja interakcije između nervnih ćelija i
                                neurotransmitera serotonina. Serotonin se nalazi u mozgu i kičmenoj moždini i
                                uključen je u kontrolu ponašanja, percepcije i regulisanja raspoloženja, gladi,
                                temperature, seksualnog ponašanja, kontrole mišića i percepciji čula.
                                Pod dejstvom halucinogena konzumenti imaju osećaj da mogu da čuju boje i vide
                                zvukove. Te promene mogu biti zastrašujuće i mogu izazvati paniku. Neki
                                konzumenti LSD-a iskusili su ozbiljne zastrašujuće misli i osećaje očaja, osećaj da
                                gube kontrolu ili strah da gube razum i strah od smrti. Konzumenti LSD-a mogu
                                iskusiti „flešbek“, koji se događaju iznenada, često bez upozorenja, a do njih može
                                doći unutar nekoliko dana, meseci, ali i godinu dana kasnije nakon upotrebe LSD-a.
                                Ovi „flešbekovi“ mogu dovesti do značajno smanjenog socijalnog ili profesionalnog
                                funkcionisanja.
                            </p>
                            <h3>RIZICI I OPASNOSTI PO ZDRAVLJE</h3>
                            <p>
                                Upotreba halucinogena izaziva ozbiljna oštećenja mentalnog i fizičkog
                                zdravlja. Halucinogeni izazivaju proširene zenice, povećavaju temperaturu,
                                ubrzavaju rad srca i povećavanje krvnog pritiska. Takođe može uzrokovati pojačano
                                znojenje, gubitak apetita, povraćanje, mučninu, nesanicu, suvoću usta i drhtavicu.
                            </p>
                            <p>
                                U većim dozama krvni pritisak, puls i brzina disanja opadaju. To može biti
                                praćeno mučninom, povraćanjem, zamagljenim vidom, kolutanjem očiju, slinjenjem,
                                gubitkom ravnoteže i vrtoglavicom. Konzumenti halucinogena su često dovedeni na
                                hitnu službu zbog predoziranja ili zbog ozbiljnih nepovoljnih psiholoških efekata. Dok
                                su inotksikovani, mogu postati nasilni ili suicidalni, te stoga mogu predstavljati
                                opasnost za samog sebe ali i za druge. Velika doza može prouzrokovati napade,
                                komu ili smrt (iako je smrt češće rezultat slučajno ozleđivanja ili samoubistva za
                                vreme intoksikacije).
                            </p>
                        </div>
                    </ScrollArea>
                </div>
            ),
            Fentanil: 
            (
                <div className='text'>
                    <h2>FENTANIL</h2>
                    <ScrollArea speed={0.8} smoothScrolling>
                        <div className='column'>
                            <h3>ŠTA JE FENTANIL?</h3>
                            <p>
                                Fentanil je narkotički analgetik sa potentnošću najmanje 80 puta većom od
                                morfina. Fentanil i njegovi derivati (alfentanil, sufentanil, remifentanil i carfentanil) se
                                koriste kao anestetici i analgetici u humanoj i veterinarskoj medicini (carfentanil). Oni
                                su podvrgnuti međunarodnoj kontroli, kao i niz visoko potentnih nefarmaceutskih
                                derivata fentanila (NPF), kao što je 3-metilfentanil, koji se sintetizuje nezakonito i
                                prodaje kao „sintetički heroin“.
                            </p>
                            <p>
                                Fentanil i njegove soli su u obliku belog granuliranog ili kristalnog praha.
                                Nezakoniti oblici obuhvataju svetlo žuti prah koji se naziva &quot;White Persian&quot; i koji
                                sadrži 3-metilfentanil, a ponekad i &quot;paper trips&quot; (tanki komadi kartona impregnirani
                                fentanilom).
                            </p>
                            <p>
                                China White, Synthetic Heroin, Drop Dead, Flatline, Lethal Injection, Apache,
                                China Girl, Chinatown, Dance Fever, Great Bear, Poison, Tango &amp; Cash, TNT. Perc-
                                o-Pops and Lollipops , su ulični nazivi za fentanil
                            </p>
                            
                        </div>
                        <div className='column'>
                            <h3>DEJSTVO</h3>
                            <p>
                                Osim analgezije (smanjenje bola), fentanili kao grupa izazivaju pospanost i
                                euforiju, pri čemu je ova druga manje izražena nego kod heroina i morfina. Najčešće
                                nuspojave su mučnina, vrtoglavica, povraćanje, umor, glavobolja, konstipacija,
                                anemija i periferni edemi.
                            </p>
                            <p>
                                Tolerancija i zavisnost brzo se razvijaju nakon višestruke upotrebe.
                                Кarakteristični simptomi povlačenja dejstva fentanila (znojenje, anksioznost, dijareja,
                                bol u kostima, grčevi u trbuhu, drhtavice ili &quot;goose flash&quot;) javljaju se kada je upotreba
                                zaustavljena.
                            </p>
                            <h3>RIZICI I OPASNOSTI PO ZDRAVLJE</h3>
                            <p>
                                Ozbiljne interakcije se mogu javiti kada se fentanili mešaju sa heroinom,
                                kokainom, alkoholom i drugim depresantima CNS-a, npr. benzodiazepinima.
                            </p>
                            <p>
                                Predoziranje dovodi do respiratorne depresije. Iznenadna smrt se može javiti i
                                zbog srčanog zastoja ili teške anafilaktičke reakcije.
                            </p>
                        </div>
                    </ScrollArea>
                </div>
            ),
    },
};

const ContentSection = {
    NegativeEffectsOfDrugs: 1,
    Amphetamines: 2,
    Marijuana: 3,
    Ecstasy: 4,
    Heroin: 5,
    Cocaine: 6,
    Steroids: 7,
    NewPsychoactiveSubstances: 8,
    Hallucinogens: 9,
    Fentanil: 10
};

class NowSeriously extends Component {

    constructor(props) {
        super(props);

        this.state = {
            url: props.location.state && props.location.state.url ? props.location.state.url : '/',
            section: ContentSection.NegativeEffectsOfDrugs
        }

        this.props.displayHeader(false);
    }

    changeContent(section) {
        this.setState({
            section: section
        });
    }

    getContent() {

        const lng = strings.getLanguage();
        
        switch(this.state.section) {
            case ContentSection.NegativeEffectsOfDrugs: return Content[lng].negativeEffectsOfDrugs;
            case ContentSection.Amphetamines: return Content[lng].Amphetamines;
            case ContentSection.Marijuana: return Content[lng].Marijuana;
            case ContentSection.Ecstasy: return Content[lng].Ecstasy;
            case ContentSection.Heroin: return Content[lng].Heroin;
            case ContentSection.Cocaine: return Content[lng].Cocaine;
            case ContentSection.Steroids: return Content[lng].Steroids;
            case ContentSection.NewPsychoactiveSubstances: return Content[lng].NewPsychoactiveSubstances;
            case ContentSection.Hallucinogens: return Content[lng].Hallucinogens;
            case ContentSection.Fentanil: return Content[lng].Fentanil;
        }
    }

    getSideBar() {

        return [
            <h2>{ strings.nowSeriously.sideBar.moreOnSpecificDrugs }</h2>,
            <div className='link' onClick={ () => this.changeContent(ContentSection.NegativeEffectsOfDrugs) }>
                { strings.nowSeriously.sideBar.negativeEffectsOfDrugs }
            </div>,
            <div className='link' onClick={ () => this.changeContent(ContentSection.Amphetamines) }>
                { strings.nowSeriously.sideBar.Amphetamines }
            </div>,
            <div className='link' onClick={ () => this.changeContent(ContentSection.Marijuana) }>
                { strings.nowSeriously.sideBar.Marijuana }
            </div>,
            <div className='link' onClick={ () => this.changeContent(ContentSection.Ecstasy) }>
                { strings.nowSeriously.sideBar.Ecstasy }
            </div>,
            <div className='link' onClick={ () => this.changeContent(ContentSection.Heroin) }>
                { strings.nowSeriously.sideBar.Heroin }
            </div>,
            <div className='link' onClick={ () => this.changeContent(ContentSection.Cocaine) }>
                { strings.nowSeriously.sideBar.Cocaine }
            </div>,
            <div className='link' onClick={ () => this.changeContent(ContentSection.NewPsychoactiveSubstances) }>
                { strings.nowSeriously.sideBar.NewPsychoactiveSubstances }
            </div>,
            <div className='link' onClick={ () => this.changeContent(ContentSection.Hallucinogens) }>
                { strings.nowSeriously.sideBar.Hallucinogens }
            </div>,
            <div className='link' onClick={ () => this.changeContent(ContentSection.Fentanil) }>
                { strings.nowSeriously.sideBar.Fentanil }
            </div>,
        ];
    }

    render() {
        return (
            <div id="now-seriously-page" className="page">
                
                <Link to={'/'}>
                    <img className='logo' src={'images/exit-logo.jpg'} />
                </Link>
                
                <Link to={this.state.url}>
                    <img className='arrow' src={'images/arrow.png'} />   
                </Link>

                <div className='container'>
                    <h1>{ strings.nowSeriously.title }</h1>

                    <div className='content'>
                       
                        { this.getContent() }
                        
                        <div className='sidebar'>
                            { this.getSideBar() }
                        </div>
                    </div>
                </div>

            
                <FacebookShareButton url={ this.state.url }>
                            <a className='share'>{ strings.footer.share }</a>
                </FacebookShareButton>

                <div className='logos'>
                    <a href='https://www.exitfondacija.org/' target='_blank'>
                        <img src={'images/exit-fondacija-green.png'} />
                    </a>
                    <a href='https://mts.rs/O-Telekomu' target='_blank'>
                        <img src={'images/telekom-green.png'} />
                    </a>
                    <a href='https://www.zdravlje.gov.rs/' target='_blank'>
                        <img src={'images/vlada-green.png'} style={{ height: '90px' }}/>
                    </a>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        displayHeader: Actions.displayHeader
    }, dispatch);
}

function mapStateToProps({ siteDataReducers })
{
    return { siteData: siteDataReducers };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NowSeriously));