import React, {Component} from 'react'
import { render } from "react-dom";
import range from "lodash.range";
import { scaleLinear } from "d3-scale";
import { interpolateInferno } from "d3-scale-chromatic";
import { easeElastic } from "d3-ease";
import { NodeGroup } from "react-move";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import * as Actions from "../actions/Actions";
import Sound from 'react-sound';
import {getRandom} from "../util/Random";


class Step1Sound extends Component {

    constructor(props) {
        super(props);

        this.state = {
            startFart: false,
            startPill: false,
            fartDid: false,
            pillDid: false,
        }

        this.props.displayHeader(true);
    }

    componentWillReceiveProps(props) {
        
        if(props.startAudio) {
            setTimeout(() => {
                this.setState({
                    startFart: props.startAudio,
                });
            }, 350);

            setTimeout(() => {
                this.setState({
                    startPill: props.startAudio,
                    fartDid: true,
                });
            }, 550);

            setTimeout(() => {
                this.setState({
                    pillDid: true
                });
            }, 650);
        }
        else {
            this.setState({
                startFart: false,
                startPill: false,
                fartDid: false,
                pillDid: false
            });
        }
    }

    render() {


        const random = getRandom(1, 100);

        return (
            <div style={{ position: 'absolute' }}>
                <Sound
                    url={'/sounds/fart.mp3'}
                    playStatus={ (this.state.startFart && !this.state.fartDid) ? Sound.status.PLAYING : undefined }
                    playFromPosition={0 /* in milliseconds */}
                />
                <Sound
                    url={random > 30 ? '/sounds/pills.mp3' : '/sounds/pills3.wav'}
                    playStatus={ (this.state.startPill  && !this.state.pillDid) ? Sound.status.PLAYING : undefined }
                    playFromPosition={0 /* in milliseconds */}
                    loop={true}
                />
            </div>
                
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        displayHeader: Actions.displayHeader
    }, dispatch);
}

function mapStateToProps({ siteDataReducers })
{
    return { siteData: siteDataReducers };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step1Sound));