import React, {Component} from 'react'
import { render } from "react-dom";
import range from "lodash.range";
import { scaleLinear } from "d3-scale";
import { interpolateInferno } from "d3-scale-chromatic";
import { easeElastic } from "d3-ease";
import { NodeGroup } from "react-move";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import * as Actions from "../actions/Actions";
import Sound from 'react-sound';
import {getRandom} from "../util/Random";


class Step3Sound extends Component {

    constructor(props) {
        super(props);

        this.state = {
            startBoom: false,
            boomDid: false,
            blowoutStart: false,
            blowoutDid: false,
        }

        this.props.displayHeader(true);
    }

    componentWillReceiveProps(props) {
        
        if(props.startAudio) {
            setTimeout(() => {
                this.setState({
                    startBoom: props.startAudio,
                });
            }, 200);

            /*setTimeout(() => {
                this.setState({
                    boomDid: true
                });
            }, 400);*/
        }
        else {
            this.setState({
                startBoom: false,
                boomDid: false
            });
        }

        if(props.startBlowout) {
            setTimeout(() => {
                this.setState({
                    blowoutStart: props.startBlowout,
                });
            }, 200);
        }
        else {
            this.setState({
                blowoutStart: false,
                blowoutDid: false
            });
        }
    }

    render() {

        return (
            <div style={{ position: 'absolute' }}>
                <Sound
                    url={'/sounds/boom.mp3'}
                    playStatus={ (this.state.startBoom && !this.state.boomDid) ? Sound.status.PLAYING : undefined }
                    playFromPosition={0 /* in milliseconds */}
                />
                <Sound
                    url={'/sounds/blowout.mp3'}
                    playStatus={ (this.state.blowoutStart) ? Sound.status.PLAYING : undefined }
                    playFromPosition={0 /* in milliseconds */}
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        displayHeader: Actions.displayHeader
    }, dispatch);
}

function mapStateToProps({ siteDataReducers })
{
    return { siteData: siteDataReducers };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step3Sound));